/* ---------------------------------- Responsive Media Styles ---------------------------------*/



@media (min-width: 1200px) {
	 
}

 

@media only screen and (min-width: 768px) {
	 
	 .tophead .tophead-right .dropdown-menu { display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0; top:180%;  }
	.tophead .tophead-right  .dropdown-toggle:hover .dropdown-menu { transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
	 
	 .navbar .nav-item .dropdown-menu {  display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0; top: 180%  }  
	.navbar .nav-item:hover .dropdown-menu { transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }  
	
	
}


@media only screen and (min-width: 767px) and (max-width: 1024px) {

   .login-bg h1 {  font-size: 49px; margin-top: 47px; line-height: 48px; }
   .login-bg h2 { font-size: 21px; margin-top: 17px; margin-bottom: 20px; }
   .loginTabs .nav-tabs .nav-link { padding: 19px 16px;}
   .loginTabs .tabfliping { padding: 20px 35px;}
   .loginTabs .nav-tabs .nav-link img { height: 33px;}
    
   .agreebox h3 { padding: 25px; }
   .agreecontent { padding: 20px;  }
   .agreescroll { margin:0 10px 0 20px; } 
   .agreebtn { margin: 25px !important;  }
     

   .notifyicon { margin-right: 20px; }
   .homenews-bg { padding: 15px;}
   .home-newsimg { height: 143px; }
   .homenewstxt p { font-size: 16px; margin-top: 20px; font-weight: bold;}
   .homenewstxt h6 { font-size: 13px;} 

   .birthday-bg { margin-top: -20px;}
   
   .inline-spacing { justify-content: flex-start;}
   .btn-betweenspaing .btn { margin: 10px 10px 0 0 }
   
   .tableaction .dropdown-menu { top: inherit !important; bottom: 0 !important;}
   .tableaction:hover .dropdown-menu { top: inherit !important; }
   .tableaction .dropdown-menu { height: 83px;}

   .news-detail h2 { font-size: 22px; margin: 0px 0 10px;   }
   .news-detail h4 { font-size: 18px; } 
   .news-detail p { font-size: 16px; line-height: 22px; margin-top: 0px; }

   .analytic-content { width: 31%; padding: 10px;}
   .analytic-content h3 { font-size: 16px; }
  .analytic-content h4 { font-size: 22px; }
  .ticketbg { padding: 10px;}
  .ticketbg h3 { font-size: 15px; }
  .ticketbg h4 { font-size: 20px; }

  .ideabox h4 { font-size: 14px; display: inline-block; }
  .ideabox h5 { font-size: 16px; margin-bottom: 0; }
  .ideabox h6 { font-size: 14px; line-height: 18px; margin-top: 10px;}
  .ideabox .btn-blanktd { font-size: 14px; }

  .ideastatusbar h4 { font-size: 13px; }
  .ideastatusbar p { font-size: 11px; }
  .ideastatusbar .ideadefstat { width: 23px; height: 23px; }
  .ideastatusbar span { width: 15px; height: 15px; line-height: 15px; font-size: 12px; }

}


/* Medium : 647px */

@media only screen and (max-width: 767px) {
	
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
   .login-bg {}
	.login-bg h1 {  font-size: 45px; margin-top: 35px; line-height: 45px; }
	.login-bg h2 { font-size: 18px; margin-top: 17px; margin-bottom: 20px; } 

	.loginTabs .nav-tabs .nav-link { padding: 12px 16px;}
	.loginTabs .tabfliping { padding: 20px;}
	.loginTabs .nav-tabs .nav-link img { height: 33px;}
	.btn-primary, .btn-warning, .btn-primary-inner, .btn-secondary-inner, .btn-outline-primary, .btn-outline-danger, .btn-outline-secondary  {  font-size: 15px;}
	.btn-primary-inner, .btn-secondary-inner, .btn-outline-primary, .btn-outline-danger, .btn-outline-secondary { padding: 5px 18px;  }
	
	.loginTabs .btn-primary { padding: 7px; font-size: 17px;}
	.loginTabs h3 { font-size: 22px; margin-bottom: 20px; }

   .agreebox { padding-bottom: 1px !important; }

   .agreebox h3 { padding: 20px; margin-bottom: 0; }
   .agreecontent { padding: 10px;  }
   .agreescroll { margin:0 10px 0 20px; max-width: 95%; padding-right: 10px; }
   .agreecontent h3 { font-size: 14px; margin-bottom: 5px;}
   .agreecontent p { font-size: 13px; }
   .agreecontent .logCheck { margin-top: 10px; font-size: 13px;}
   .agreecontent .checkmark { width: 18px; height: 18px; }
   .agreecontent .logCheck .checkmark:after { left: 6px; top: 2px; }
   .agreebtn { margin: 20px !important; display: block;}

	.loginTabs .form-group:first-child { margin-bottom: 20px; }
	.loginTabs label { font-size: 15px;	}
	.forgettxt { font-size: 16px; }
	.loginTabs { border-radius: 20px; }
	.loginTabs .nav-fill .nav-item { border-radius: 0px 0px 20px 0;  } 
	.loginTabs .nav-fill .nav-item:last-child { border-radius: 0 0 0 20px; }   
	.loginTabs .nav-tabs .nav-link.active { border-radius: 20px 20px 0px 0px;}
	 
	.mand-coursehead { font-size: 16px; line-height: 22px; }
	.mand-coursedesc { font-size: 13px; }
	.mand-rightwrng { font-size: 22px; padding: 15px 20px; line-height: normal; }
	.mand-page-head { font-size: 30px; margin-bottom: 20px;  }
	.mad-course-image { width: 70px; height: 70px; border-radius: 5px; 	}
	.mand-cou-warning { font-size: 11px;}

 
   .homenews-bg { padding: 10px;}
   .home-newsimg { height: 150px; }
   // .homenewstxt p { font-size: 14px; margin-top: 15px; margin-bottom: 15px; font-weight: bold;}
   // .homenewstxt h6 { font-size: 13px;} 
   .homenesnew-bg .tab-content { padding: 10px 15px; height: auto;}
   .homenesnew-bg h4 { font-weight: bold; font-size: 16px; margin-top: 10px; }
   .homenewstxt p { font-size: 13px; margin-top: 0; margin-bottom: 15px; line-height: normal;}
   .homenewstxt h6 { font-size: 13px;} 
   .noticeheading h5 { font-size: 16px; padding-bottom: 8px;}
   .noticetxtnew { line-height: 19px; font-size: 13px; }
   .homenesnew-bg .nav-tabs .nav-link { font-size: 13px; padding: 0 17px; }
   .homenesnew-bg .nav-tabs .nav-link.active { font-size: 13px; }
   .homenesnew-bg .slick-next { right: -24px;}
   .homenesnew-bg .slick-prev { left: -24px;}

   .approval-bg .nav-tabs .nav-link { display: flex; white-space: nowrap;}
   .approval-bg .nav-tabs .nav-item { /*max-width: 35%; flex: 0 0 35%; */margin-right: 16px;}
   .approval-bg .nav-tabs { flex-wrap: inherit; overflow-x: scroll; }
   .approval-bg .nav-tabs::-webkit-scrollbar { display: none; /* Chrome, Safari and Opera */ -ms-overflow-style: none; /* IE and Edge */ scrollbar-width: none; /* Firefox */ }
   .mainpage-head { font-size: 16px; }
	.btn-create { padding:0px 3px; font-size: 13px;}
	.timelines h5 { font-size: 14px; max-width: 100%; }
	.timelines .teamtxt { margin-left: 10px;}
	.timelines span { float: none;}
	.team-multiple { margin-bottom: 10px;}
	.team-multiple + .teamtxt { margin-left: 0px;}
	.birthday-bg { margin-top: -20px;}
	.slick-dots li button, .slick-dots li { width: 9px; height: 9px; }

	header {   border-radius: 0; margin: 0 -15px; }
	.notifyicon { margin-right: 0;}
	.topheadusernot li { margin-left: 15px;} 
	.topheaduser { position: relative; }
	.mob-head-left-sec img.mob-lgo { height: 35px; margin-right: 10px; }

	.notifytabs .nav-tabs .nav-item { margin-left: 0;}

	.inline-spacing { justify-content: flex-start;}

	.inline-spacing { justify-content: flex-start;}
   .btn-betweenspaing .btn { margin: 10px 10px 0 0 }
   
   .tableaction .dropdown-menu { top: inherit !important; bottom: 0 !important;}
   .tableaction:hover .dropdown-menu { top: inherit !important; }
   .tableaction .dropdown-menu { height: 83px;}

   .innerheadsec { padding: 0px 15px 15px; }
   .tablecured tr td, .tablecured thead th, .tablecured tr td.text-theme, .page-item .page-link, .tablecured .teamtxt h5  { font-size: 14px; }
   .tablecured tr td .badge { font-size: 12px;}
   .tablecured td, .tablecured th { padding: 9px 0.75rem; }
   .tablecured tr td:last-child { padding: 9px 8px; }
   .sortinglist {  padding: 5px 14px; font-size: 15px; }
   .previpag .page-link span { font-size: 26px; }

   .news-detail h2 { font-size: 20px; margin: 0px 0 10px; line-height: 24px;   }
   .news-detail h4 { font-size: 16px; } 
   .news-detail p { font-size: 16px; line-height: 20px; margin-top: 15px; }

   .filter-popup.modal.fade .modal-dialog { -webkit-transform: translate(-100%) scale(1); transform: translate(-100%) scale(1); }
   .mobilmenupoup .modal-content { background: none; }
   .mobilmenupoup .modal-header { padding: 0; }
   .mobilmenupoup .modal-body { padding: 10px 16px; }
   
   .mobilmenwrapper { width: 82px; }
   aside { top: 0; }
   aside li, .sidelogo { margin-bottom: 12px;}
   .leftmenubox { padding: 7px;}
   .left-menu li { padding: 7px 6px !important; font-size: 12px;}
   .left-menu li img { height: 14px; }
   .left-menu { width: 186px; }
   aside li:hover .left-submenu { width: 314px; }
   .sub-menutxt li img { height: 11px; }
   .sub-menutxt li { font-size: 11px; padding:4px 5px !important; }
   .sub-menutxt { margin: 0; margin-left: 4px; }
   .mobilmenupoup .btn-close { left: 108px; }
   .pollbox { width: 250px; bottom: -100px; }
   .pollbox h5 { font-size: 16px; }
   .pollbox h4 { font-size: 14px; }
   .pollbox .logCheck { font-size: 14px;}
   .pollbox .teamtxt h5 { font-size: 13px;}
   .helpbox .helpboxTxt h4 { font-size: 14px; padding: 3px 10px;}
   .helpbox .leftmenubox { padding: 10px; }
   .helpbox .helpboxTxt { padding: 10px; }
   .helpbox .helpboxTxt li { margin-bottom: 10px; }
   .userboxes { margin-bottom: 10px; padding: 10px;}
   .userboxes h5 { font-size: 17px; }
   .userboxes p { font-size: 14px; }
   .inner-page-title { font-size: 21px; }
   .breadcrumb { font-size: 16px;}
   .breadcrumb-item + .breadcrumb-item::before { font-size: 10px; top: 4px;}
   .dropdown-item { font-size: 14px; }
   .delteroleop {  right: 10px; bottom: 10px; }
   .statusroleop {  right: 10px; bottom: 42px; }

   .perhead h4 { font-size: 18px; margin-top: 15px;}
   .perhead p { margin-bottom: 15px; font-size: 14px; }
   .permission-box h4 { font-size:14px;  }
   .permission-box h5 { font-size:14px; }
   .permission-box h5 i { font-size:13px; margin-right: 3px;}

   .permission-box h6 { font-size:13px; margin-left: 20px; }
   .permission-box h6 i { display: none; }
   .permission-box .logCheck { font-size: 13px;}

   .usermyprofile ul { padding: 8px; }
   .usermyprofile li {  font-size: 15px;  padding: 8px 9px; }
   .usermyprofile .notificationbox { width: 210px; }

   .package-section h5 { font-size: 16px;  }
   .package-section h6 { font-size: 13px; margin-top: 10px;  }  
   .pack-details h5 { font-size: 13px;}

   .content-wrapper-inner2 .innerheadsec { padding: 0px 15px 15px; }

   .analytic-content { width: 46.5%; margin-right: 8px; padding: 10px;}
   .analytic-content h3 { font-size: 14px; }
  .analytic-content h4 { font-size: 20px; }
  .ticketbg { padding: 10px;}
  .ticketbg h3 { font-size: 14px; }
  .ticketbg h4 { font-size: 18px; }
  .chart-boxes { padding: 10px; }
  .poll-sna-head h3 { font-size: 15px; }
  .poll-sna-head select { height: 27px; padding-left: 4px; background-position: 97% 4px;}
  .poll-sna-box { padding: 10px;}
  .anarolesdetails { margin-left: 0;}

  .jvactionsbutton { position: relative;}
   .purchaseinnertabs .nav-tabs {  padding: 0 16px; }

  .ideabox h4 { font-size: 14px; display: inline-block; margin: 10px 0 5px; }
  .ideabox h4 i { margin: 0 5px;}
  .ideabox h5 { font-size: 16px; margin-bottom: 0; }
  .ideabox .btn-warning { margin-left: 0; margin-right: 10px; font-size: 13px; }
  .ideabox h6 { font-size: 14px; line-height: 18px; margin-top: 10px;}
  .ideabox .btn-blanktd { font-size: 14px; }

  .action-boxposit { position: absolute; top: 10px; right: 0px; }
  .ideabox h4 i.ideacomments { margin-bottom: 13px;}
  .ideabox .tableaction .dropdown-menu { height: auto;}

  .ideastatusbar h4 { font-size: 14px; margin-top: 0; } 
  .ideastatusbar .ideadefstat { float: left; margin-right: 10px; } 
  .ideastatusbar { padding-bottom: 1px;}
  .ideastatusbar li { display: block; width: 100%; margin-bottom: 19px; }
  .ideastatusbar li:after { width: 4px; height: 95%; border-top: none; border-left: #afafaf 2px dashed; left: 12px; top: -78%; }
  .ideastatusbar li.completed:after {  border-left: #34A853 2px dashed;  }
  .ideastatusbar li.rejected:after {  border-left: #f44336 2px dashed;  }

  .policyhead { padding: 6px 0; font-size: 19px; }
  .surveyviewquestions h3 { font-size: 15px; }
  .surveyviewquestions p { font-size: 13px; line-height: 20px;}
  .question-boxpollsurvey h4 {  font-size: 14px;  }
   .question-boxpollsurvey h5 { font-size: 14px; margin-bottom: 5px; }
   .question-boxpollsurvey { margin-top: 20px;}
   .answerboxpollsuy span {  font-size: 14px; }
   .answerboxpollsuy .logCheck { padding-left: 29px; font-size: 14px;}
   .answerboxpollsuy { margin-top: 10px;}

   .biginnertabs .nav-tabs .nav-link { font-size: 15px; padding: 7px 10px 7px 0; }
   .biginnertabs .tab-content { padding: 10px; }
   .accordion-button { font-size: 16px; padding: 10px 20px 10px 0; background-size: 15px;}
   .accordion-button.collapsed {  background-size: 15px;}
   .accordion-body { padding: 10px 0; font-size: 15px; line-height: 20px;}

   .help-deskbg { padding: 20px; }
   .help-deskbg h2 { font-size: 23px; }
   .help-deskbg h3 { font-size: 14px; }
   .help-deskbg h4 { font-size: 27px; }


   .ticketviewbox {  padding: 15px; margin-bottom: 10px; height: auto;}

   .ticketviewbox h3 { font-size: 17px;  }
   .ticketviewbox h4, .ticketviewbox h5, .ticketviewbox h6, .ticketviewbox p { font-size: 14px; }  
   .ticketviewbox h4 i { font-size: 11px !important; }  
   .ticketviewbox ul { padding-left: 20px; font-size: 14px; }  

   .viewraisehead { font-size: 16px; margin-bottom: 15px; }

   .agent { margin-bottom: 20px;}
   .ticketreplytxt { font-size: 13px; padding: 5px; }
   
   .raise-scroll { max-height: 150px;}
   
   .raisereplybox textarea { height: 40px;}
   .raisereplybox button { right: 20px;   width: 43px; height: 32px; line-height: 32px; padding: 0; font-size: 15px; top: 20px;}

   .timeline-box h4 { font-size: 14px; }
   .timeline-box h5 { font-size: 13px; }
   .timeline-box h6 { font-size: 12px; }
   .timeline-box p { font-size: 14px; }

   .btn-digital { font-size: 12px; padding: 2px 7px; }
   .emp-digitalbox .modal-header { padding: 15px 15px 0; }
   .emp-digitalbox .modal-body {  padding: 15px; }

   .listenpopup .preloader p { font-size: 30px; line-height: 35px; }
   .listenpopup .loadingbar span { width: 3px; height: 3px; margin-right: 3px;  }

   .page-headapprover { padding: 12px 14px 6px; font-size: 15px; }

   .policylistnames { display: flex; flex-wrap: inherit; overflow-x: scroll; overflow-y: hidden; padding: 9px 0 0 10px; margin: 10px 0 0; }
   .policylistnames li.active:before { display: none; }
   .policylistnames li { padding-left: 0; margin-bottom: 0; font-size: 13px !important; font-weight: 700; margin-right: 15px; display: flex; white-space: nowrap; line-height: normal; padding-bottom: 5px;  }
   .policylistnames li.active a { font-size: 13px !important; color: #0195d4; }
   .policylistnames li.active  { font-size: 13px !important; border-bottom: 2px solid #4a9bd4; color: #0195d4; }
   .policylistnames li span.numberpurchse { width: 17px; height: 17px; line-height: 17px; font-size: 10px; margin:0 0 0 5px !important; }

   .policylistnames li.backleftlist { margin-top: 0; }
   .policylistnames li.backleftlist span { font-size: 10px; }
   .policylistnames li.backleftlist span i { font-size: 10px !important; }
   .policylistnames::-webkit-scrollbar { display: none; }

   .btn-betweenspaing .show.dropdown { display: none;}

   .ginniechatgpt .answerboxpollsuy .logCheck { padding: 6px; min-width: 131px;  font-size: 17px; }
   .ginniechatgpt .raisereplybox .btn-blank { width: 54px; }

   .addusertabs > .nav-tabs .nav-link { background: none; padding-bottom: 11px;}
   .addusertabs > .nav-tabs .nav-item::after { top: 20px; height: 100%; border: none; border-left: #afafaf thin dashed; width: 4px; left: 16px; }
   .addusertabs > .nav-tabs { padding:10px 16px;}
   .addusertabs > .nav-tabs .nav-link span { border-radius: 50%; }
   .addusertabs > .nav-tabs .nav-link.active { font-weight: bold; }
}

/* Small : 320px */

@media screen and (max-width: 574px) {
	.mandatory-bg { margin: 10px; padding: 15px 0; }
	.input-search input { font-size: 14px;  }
	.inputshowmand input { width: 120px; }  
	.input-search { padding: 5px 13px 5px 10px; height: 36px; }
	.input-search i { font-size: 13px;}
	.mandtop-user div { display: none; }
	.mandtop-user { border-radius: 50%; padding: 0; }
	.mandtop-user img { margin: 0; height: 36px; width: 36px;}
	.mand-page-head { font-size: 26px; margin-bottom: 10px;  }

	.course-view { padding: 15px;}
	.mand-coursehead { font-size: 15px; line-height: 20px; }
	.mand-coursehead i { font-size: 11px; margin: 0 5px; }
	.mand-coursedesc { font-size: 12px; margin-bottom: 15px; }
	.mand-rightwrng { font-size: 22px; padding: 15px 20px; line-height: normal; }
	.mand-cousebtn { font-size: 11px;}
	.mand-cou-warning { font-size: 10px; margin-top: 10px;}
	.mand-rightwrng { font-size: 15px; padding: 10px; line-height: normal; }

	.notificationbox { width: 320px; right: 20px; }
	.notificonhoverbox:hover .notificationbox { top: 120%; }

	.pagination .page-item:nth-last-child(2), .pagination .page-item:nth-last-child(3) { display: none;}
   .pagination { justify-content: flex-start; margin-top: 15px; margin-right: 0; margin-left: 15px;}

   .innergroup label { font-size: 14px;}
   .filter-popup .modal-title { font-size: 22px; }
   .modaldefaultclosecenter .modal-title { font-size: 19px; }
   .modal-dialog button  { margin-left: 5px !important; margin-bottom: 5px; }
   .modal-dialog button:first-child { margin-left: 0px !important;}
   .modal-header { padding: 12px;}
   .modaldefaultclose .btn-close { padding: 12px; background-size: 12px;}
   .modaldefaultclosecenter .btn-close { top: -7px; right: -7px; background-size: 9px;}
   .otp-input input { width: 40px; height: 50px; line-height: 50px; font-size: 21px; }

   .admin-guide-data h3 { font-size: 18px;}
   .admin-guide-data p,  .admin-guide-data ul li { font-size: 14px;  word-break: break-all;}
   .adminguidelist { margin-top: 10px; }
   .adminguidelist .accordion-button { font-size: 15px; padding: 8px 0;}
   .adminguidelist .accordion-body ul li { margin-bottom: 5px; }
  .adminguidelist .accordion-body ul li, .adminguidelist .accordion-body ul li a { font-size: 15px; }

  .profile-detail .profile-cover { max-width: 150px; height: 150px; margin: -60px auto 0;}
  .profile-detail .profile-cover img { border-radius: 50%; border: #fff 5px solid;}
  .profile-detail { text-align: center; }
  .profile-detail h2 { margin-top: 20px; font-size: 20px; letter-spacing: 1px; margin-bottom: 3px;}
  .profile-detail h3 {  font-size: 15px; line-height: 22px; }
  .profile-detail h3 span {  font-size: 13px; margin-left: 10px; }
  .profiletab .nav-tabs { border: none; justify-content: start; padding: 0; }
  .profiletab .nav-tabs .nav-link { padding: 7px 0; margin-right: 0;} 
  .profilesecdetail h4, .profilesecdetail h5  {font-size: 13px; margin-bottom: 17px; }
  .profiletab .tab-content { border-top: #F8F8F8 10px solid; padding-bottom: 0px; }
  .chtgptbox .raise-scroll { max-height: 350px;}
  .raisereplybox .btn-sound { right: 73px; font-size: 16px; padding-top: 5px;}

  .data-certibg .mand-page-head { font-size: 24px; margin-bottom: 20px; }
  .data-correctionbox { border-radius: 70px; padding: 8px; }
  .data-correctionbox .icondata { width: 50px; height: 50px; font-size: 20px; line-height: 50px;}
  .data-correctionbox h2 { font-size: 17px; margin-left: 10px; }
  .data-coreectafterwh:after { width: 75px; height: 75px; left: -8px; top: -4px; }
  .data-coreectafterwh { margin-bottom: 25px;}
  .data-cert-rightwrng { font-size: 12px; line-height: 17px; margin-bottom: 10px;}
  .data-certiprogreess { margin-bottom: 15px; }
  .data-certipopupinfo li { font-size: 15px; line-height: 22px; }
  .data-certipopupinfo { padding-left: 17px;}
  .data-certipopupinfo li::before { width: 14px; height: 14px; line-height: 14px; font-size: 11px; left: -19px;}
}

@media (min-width: 320px) and (max-width: 440px) {
	 
}


@media screen and (max-width: 354px) {
	.notificationbox { width: 290px; }
}

