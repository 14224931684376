
:root{
    --basex:10px;
    --basey:10px;

    --white:#fff;
}

.transport-portal-container{
    width: 100%;
    height: 100%;
  
    /* width: auto;
    height: auto; */

    padding: var 0;

}
 .table-responsive{
 overflow-x: scroll;

} 
.transport-portal-content{
    background-color: var(--white);
    
    padding: var(--basey) var(--basex);
    width:100%;
    /* width: auto; */
}

.heading{
    width: 100%;

    padding: calc(2 * var(--basex));

    display: flex;
    justify-content: space-between;
}
.heading p{
    margin-right: 00px;
}
.heading h3{

}

.controls{
    width: 100%;
    /* width: auto; */
    display: flex;
    justify-content: space-between;

    padding:0 calc(2 * var(--basex)) calc(2 * var(--basey)) calc(2 * var(--basex));
}
.left{

}
.table_contain{
    padding:0 calc(2 * var(--basex));
}
.ctas{
    width: 100%;
    /* width: auto; */
    display: flex;
    justify-content: space-between;
    padding:0 calc(2 * var(--basex)) calc(2 * var(--basey)) calc(2 * var(--basex));
}
.ctas_left{

}
.ctas_left div{
    margin-right: calc(2 * var(--basex));
}

.btn btn-primar{
background-color: #0195D4;
border-radius: 5px;
padding: 4px 6px;
}
 
.valuebox{
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #0195D4;
    padding: 4px 6px;
    border-radius: 5px;
    margin-right: 30px;
    color: #0195D4;
    height: 100%;
}

.valueboxone{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 6px;
    border-radius: 5px;
    margin-right: 10px;
    color: #0195D4;
    height: 100%;
    
}

.inputtext{
   
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px ;
    width: 140px;
    font-size: medium;
    border-radius: 5px;
    margin-right: 30px;
    border-color: #0195D4;
    
}
.billrow{
    padding-bottom: 10px;
}

/* .inputtext::placeholder{
    color: black;
} */


 

.transport-portal-label{
    margin-right: 0rem;
    color: #0195D4;
}
.billprocess-portal-label{
    margin-right: 1rem;
    color: #0195D4;
}

.transport-portal-table table td,
.transport-portal-table table th{
    padding: 5px !important;
}

.transport-portal-table input{
    height: 20px;
    font-size: 12px !important;
}

.transport-portal-table select{
    padding: 0 0 0 5px !important;
    height: 20px !important;
    font-size: 13px !important;
    -webkit-appearance: checkbox !important;
    
}