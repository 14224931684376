html {
  font-family: "Calibri";
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Calibri";
  -webkit-font-smoothing: antialiased;
  font-size: 1rem !important;
  background: #f8f8f8;
  font-weight: 400;
}

img {
  max-width: 100%;
}

.fw-light {
  font-weight: 300;
}

.fw-bold {
  font-weight: 700;
}

.text-black {
  color: #000 !important;
}

.text-theme {
  color: #0195d4;
}

a.text-theme {
  color: #0195d4;
}

a.text-theme:hover {
  color: #1964af;
}

.cursor-pointer {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: #1964af;
}

.btn-primary {
  background: #0062a9;
  border-color: #0062a9;
  border-radius: 8px;
  font-size: 20px;
}

.btn-primary:disabled {
  background: #4a9bd4;
  border-color: #4a9bd4;
  opacity: 1;
  cursor: not-allowed;
}

.btn-warning {
  background: #edf4d3;
  border-color: #edf4d3;
  color: #7d7d7d;
  border-radius: 8px;
  font-size: 20px;
}

.btn-success {
  background-color: #56cb82;
  border-color: #56cb82;
}

.f-11 {
  font-size: 4px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.fa-20 {
  font-size: 18px !important;
}

.content-wrapper {
  margin-bottom: 15px;
}

.content-wrapper-inner {
  background: #fff;
  padding: 15px 0;
  margin: 18px 0 15px;
}

.btn-primary-inner {
  background: #0195d4;
  border-color: #0195d4;
  color: #fff;
  padding: 5px 30px 5px 20px;
  border-radius: 5px;
  font-size: 17px;
  letter-spacing: 0.4px;
}

.btn-primary-inner:disabled {
  background: #4a9bd4;
  border-color: #4a9bd4;
  opacity: 1;
  cursor: not-allowed;
}

.btn-primary-inner:hover {
  background: #0062a9;
  border-color: #0062a9;
  color: #fff;
}

.btn-danger {
  padding: 5px 30px 5px 20px;
}

.bpi-main {
  padding: 5px 25px;
  font-size: 18px;
}

.btn-outline-danger {
  border-color: #de0000;
  color: #de0000;
  border-radius: 5px;
  padding: 5px 25px;
  font-size: 18px;
  letter-spacing: 0.4px;
}

.btn-outline-primary {
  border-color: #0195d4;
  color: #0195d4;
  border-radius: 5px;
  padding: 5px 25px;
  font-size: 18px;
  letter-spacing: 0.4px;
}

.btn-outline-primary:hover {
  border-color: #0195d4;
  color: #fff;
  background: #0195d4;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  border-color: #0195d4;
  color: #fff;
  background: #0195d4;
}

.btn-secondary-inner {
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #737373;
  padding: 5px 30px 5px 20px;
  border-radius: 5px;
  font-size: 17px;
  letter-spacing: 0.4px;
  font-weight: bold;
}

.btn-secondary-inner:hover {
  background: #d9d9d9;
  border-color: #d9d9d9;
  color: #737373;
}

.btn-secondary-inner:hover img {
  filter: invert(-1);
}

.btn-outline-secondary {
  border-radius: 5px;
  padding: 5px 25px;
  font-size: 18px;
  letter-spacing: 0.4px;
}

/* Login CSS */

.login-bg {
  background: url(../images/login-bg.jpg) no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.login-bg2 {
  background: url(../images/login-bg2.png) no-repeat;
  background-size: cover;
}

.login-bg h1 {
  font-weight: 700;
  font-size: 80px;
  line-height: 76px;
  color: #f8e822;
}

.login-bg h2 {
  font-weight: 400;
  font-size: 28px;
  margin-top: 30px;
  line-height: 40px;
  color: #f8e822;
}

.loginTabs {
  border-radius: 20px;
  overflow: hidden;
}

.loginTabs .nav-tabs {
  background: #fff;
  border: none;
}

.loginTabs .nav-fill .nav-item {
  background: linear-gradient(
    180deg,
    rgba(74, 155, 212, 0.8) 0%,
    rgba(248, 232, 34, 0.8) 100%
  );
  border-radius: 0px 0px 40px 0;
  width: 50%;
}

.loginTabs .nav-fill .nav-item:last-child {
  border-radius: 0 0 0 40px;
}

.loginTabs .nav-tabs .nav-link {
  background: none;
  border: none;
  width: 100%;
  padding: 27px;
}

.loginTabs .nav-tabs .nav-link.active {
  background: #fff;
  border-radius: 40px 40px 0px 0px;
}

.loginTabs .tab-content {
  background: #fff;
}

.loginTabs .tabfliping {
  background: rgba(217, 217, 217, 0.8);
  padding: 40px 40px 40px;
}

.loginTabs h3 {
  font-size: 35px;
  margin-bottom: 40px;
}

.jivasmall {
  display: inline-block;
  margin-top: -5px;
  margin-right: 5px;
  margin-left: -2px;
}

.loginTabs label {
  font-size: 16px;
}

.loginTabs .form-control {
  border: 1px solid #adadad;
  color: #000;
  border-radius: 9px;
  font-size: 14px;
  height: 50px;
}

.loginTabs .form-control:focus {
  border: 1px solid #4285f4;
  box-shadow: none;
}

.loginTabs .form-group:first-child {
  margin-bottom: 25px;
}

.forgettxt {
  color: #1964af;
  text-align: right;
  font-size: 16px;
}

.loginTabs .btn-primary {
  background: #1964af;
  padding: 9px;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
  font-size: 21px;
}

.loginTabs .btn-primary:hover {
  background: #064483;
}

.eyepassword {
  position: absolute;
  right: 12px;
  top: 50px;
  color: #979797;
}

.agreebox {
  padding: 0 !important;
}

.agreebox h3 {
  padding: 30px 45px 20px;
  margin-bottom: 0;
}

.agreecontent {
  padding: 20px 45px;
  border-top: #fff thin solid;
  border-bottom: #fff thin solid;
}

.agreescroll {
  margin: 0 15px 0 20px;
  max-width: 90%;
  padding-right: 20px;
  max-height: 280px;
  overflow-y: scroll;
}

.agreescroll {
  scrollbar-color: #4a9bd4 #fff;
  scrollbar-width: thin;
}

.agreescroll::-webkit-scrollbar {
  width: 5px;
  margin-right: 51px;
}

.agreescroll::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #fff;
  border-radius: 10px;
}

.agreescroll::-webkit-scrollbar-thumb {
  background-color: #4a9bd4;
  border-radius: 10px;
}

.agreecontent h3 {
  padding: 0 !important;
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.agreecontent h3:first-child {
  margin-top: 0px;
}

.agreecontent p {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px;
}

.agreebtn {
  padding: 7px 20px !important;
  margin: 25px 45px 25px !important;
}

.agreecontent .checkmark {
  border-radius: 0;
}

.agreecontent .logCheck:hover input ~ .checkmark,
.agreecontent .logCheck input:checked ~ .checkmark {
  background: #fff;
  border-color: #1964af;
}

.agreecontent .logCheck .checkmark:after,
.agreecontent .checkmark {
  border-color: #1964af;
}

.agreecontent .logCheck {
  font-size: 14px;
  padding-left: 28px;
  color: #1964af;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 0;
}

// .tab-content .tabfliping {
//     opacity: 0;
//     transform: scaleX(-1);
//     transition: all .5s ease-in-out;
// }

// .tab-content .active .tabfliping {
//     opacity: 1;
//     transform: scaleX(1);
// }

/* madnatory CSS */

.mandatory-bg {
  background: linear-gradient(
    180deg,
    rgba(248, 232, 34, 0.2) 0%,
    rgba(74, 155, 212, 0.2) 100%
  );
  border: 1px solid rgba(74, 155, 212, 0.5);
  border-radius: 20px;
  margin: 20px;
  padding: 20px 5px;
}

.input-search {
  display: inline-flex;
  background: #ffffff;
  border: 1px solid rgba(74, 155, 212, 0.2);
  border-radius: 30px;
  padding: 8px 15px 8px 12px;
  height: 48px;
  align-items: center;
}

.input-search input {
  border: none;
  background: none;
  font-size: 19px;
  color: #000;
  font-weight: 300;
  font-style: italic;
  width: 0;
  transition: all 800ms ease-in-out;
}

.input-search input:focus {
  border: none;
  background: none;
  outline: none;
}

.input-search i {
  color: #a5a5a5;
  font-size: 17px;
  cursor: pointer;
}

.inputshowmand input {
  width: 180px;
  transition: all 800ms ease-in-out;
}

.mand-tophead {
  padding: 0;
  list-style: none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0;
}

.mand-tophead li {
  display: inline;
  margin-left: 10px;
}

.mandtop-user {
  background: #ffffff;
  padding: 10px 20px;
  border: 1px solid rgba(74, 155, 212, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.mandtop-user h2 {
  font-size: 18px;
  color: #6e6b7b;
  font-weight: bold;
  margin: 0;
  line-height: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-align: right;
  margin-top: 4px;
  margin-left: auto;
}

.mandtop-user p {
  font-size: 14px;
  color: #b9b9c3;
  margin: 0;
}

.mandtop-user img {
  width: 42px;
  height: 42px;
  border: 1px solid rgba(74, 155, 212, 0.4);
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  margin-left: 20px;
}

.mand-page-head {
  font-weight: 700;
  font-size: 45px;
  margin-bottom: 30px;
  color: #4a9bd4;
}

.mandatory-newlogout {
  padding: 7px 0px;
  background: none;
  border: none;
}

.mandatory-newlogout img {
  width: 37px;
  height: 37px;
  margin-left: 10px;
}

.mandatory-newlogout .madlogout {
  border: none;
  border-radius: 0;
  width: auto;
  margin-left: 5px;
  cursor: pointer;
}

.mandatory-newlogout h2 {
  font-size: 16px;
}

body {
  scrollbar-color: rgba(24, 47, 81, 0.2) #e4e2e2;
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 5px;
  margin-right: 51px;
}

body::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: none;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(24, 47, 81, 0.2);
  border-radius: 10px;
}

#scrollpart {
  scrollbar-color: rgba(24, 47, 81, 0.2) #e4e2e2;
  padding-right: 5px;
  scrollbar-width: thin;
}

#scrollpart::-webkit-scrollbar {
  width: 3px;
  margin-right: 51px;
}

#scrollpart::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: none;
  border-radius: 10px;
}

#scrollpart::-webkit-scrollbar-thumb {
  background-color: rgba(24, 47, 81, 0.2);
  border-radius: 10px;
}

#scrollpartmeeting {
  scrollbar-color: rgba(24, 47, 81, 0.2) #e4e2e2;
  padding-right: 5px;
  scrollbar-width: thin;
}

#scrollpartmeeting::-webkit-scrollbar {
  width: 8px;
  margin-right: 51px;
}

#scrollpartmeeting::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #eee;
  border-radius: 0px;
}

#scrollpartmeeting::-webkit-scrollbar-thumb {
  background-color: rgba(24, 47, 81, 0.2);
  border-radius: 10px;
}

#scrollpartmeeting::-webkit-scrollbar-button {
  background-color: #eee;
  background-size: 5px 5px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 10px;
  width: 1em;
}

#scrollpartmeeting::-webkit-scrollbar-button:end:increment {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

#scrollpartmeeting::-webkit-scrollbar-button:start:decrement {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.course-view-list {
  max-height: 430px;
  overflow-y: scroll;
}

.course-view {
  background: #ffffff;
  border: 1px solid #c9e1f2;
  border-radius: 10px;
  padding: 16px 22px;
  margin-bottom: 20px;
}

.course-view:last-child {
  margin-bottom: 0;
}

.mad-course-image {
  width: 90px;
  height: 90px;
  border-radius: 10px;
}

.mad-course-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.mand-cou-warning {
  background: rgba(241, 136, 136, 0.25);
  border-radius: 5px;
  font-size: 12px;
  color: #9c0000;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.mand-cou-warning i {
  color: #f24e1e;
  margin-right: 6px;
}

.mand-coursehead {
  font-size: 20px;
  color: #cfcfcf;
  font-weight: 700;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 0;
}

.mand-coursehead i {
  font-size: 15px;
  color: #000;
  margin: 0 10px;
}

.mand-coursedesc {
  font-size: 14px;
  color: #737373;
  margin: 10px 0 0;
}

.mand-cousebtn {
  font-size: 12px;
  text-transform: uppercase;
}

.mand-rightwrng {
  background: rgba(255, 0, 0, 0.12);
  border: 1px solid rgba(229, 0, 39, 0.3);
  border-radius: 10px;
  font-size: 25px;
  color: rgba(255, 0, 0, 0.48);
  line-height: 31px;
  padding: 25px 20px;
  text-align: center;
}

/*  Side menu */
.sidelogo {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  text-align: center;
  padding: 16px 15px;
  margin-bottom: 18px;
}

.sidelogo img {
  height: 35px;
}

aside {
  position: sticky;
  top: 18px;
  z-index: 999999;
}

aside ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

aside li {
  margin-bottom: 13px;
  background: #4a9bd4;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
  padding: 9px 0;
  cursor: pointer;
}

aside li:hover,
aside li.active {
  background: #1964af;
}

aside li a,
aside li {
  color: #fff;
}

aside li a:hover,
aside li:hover {
  color: #fff;
}

aside li img {
  margin-bottom: 3px;
  width: 21px;
  height: 21px;
  object-fit: contain;
}

aside li p {
  margin-bottom: 0;
  line-height: 14px;
}

// aside li:last-child { padding: 8px 0; }
// aside li:nth-last-child(2){ padding: 10px 0; }
// aside li:last-child img { margin-right: 10px; }
// aside li:last-child p { display: inline-block; }

.left-menu {
  position: absolute;
  top: -10px;
  right: 0;
  width: 235px;
  left: -267px;
  transition: all 800ms ease-in-out;
}

.leftmenubox {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  border-radius: 15px;
  width: 100%;
  margin-left: 15px;
  padding: 15px 10px;
}

.left-menu li {
  background: none;
  text-align: left;
  box-shadow: none;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 10px !important;
  margin: 0;
  border-radius: 10px;
  margin-bottom: 1px;
}

.left-menu li a,
.left-menu li {
  color: #4a9bd4;
}

.left-menu li img {
  margin-right: 8px;
  height: 18px;
  width: 20px;
}

.left-menu li .leftmenuimgwith {
  display: inline-block;
}

.left-menu li .leftmenuimgwithout {
  display: none;
}

.left-menu li:hover .leftmenuimgwith,
.left-menu li.active .leftmenuimgwith {
  display: none;
}

.left-menu li:hover .leftmenuimgwithout,
.left-menu li.active .leftmenuimgwithout {
  display: inline-block;
}

.left-menu li:hover,
.left-menu li.active {
  background: #4a9bd4;
}

.left-menu li:hover a,
.left-menu li:hover,
.left-menu li.active,
.left-menu li.active a {
  color: #fff;
}

.left-menu li.divider {
  font-size: 22px;
  color: #babfc7;
  padding: 0 10px !important;
  line-height: 20px;
  margin-bottom: 8px;
}

.left-menu li.divider:hover {
  background: none;
}

.left-menu li:last-child img {
  margin-right: 8px;
}

.leftmenubox {
  display: flex;
  flex-wrap: wrap;
}

.leftmenubox ul {
  max-width: 100%;
  flex: 0 0 100%;
  max-height: 480px;
  overflow-y: scroll;
}

.leftmenubox ul::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}

.leftmenubox ul::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #eee;
  border-radius: 5px;
}

.leftmenubox ul.sub-menutxt::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #fff;
  border-radius: 5px;
}

.leftmenubox ul::-webkit-scrollbar-thumb {
  background-color: rgba(24, 47, 81, 0.2);
  border-radius: 5px;
}

.left-submenu {
  width: 455px;
  transition: all 300ms ease-in-out !important;
  left: -492px;
}

.left-submenu .leftmenubox ul {
  max-width: 49%;
  flex: 0 0 49%;
}

.sub-menutxt {
  display: none;
  margin-top: -10px;
  /*border-left: #eee thin solid; margin-left: 7px; */
}

.left-submenu ul.sub-menutxt {
  display: block;
}

.left-submenu ul.sub-menutxtnone {
  display: none;
}

.sub-menutxt li {
  font-size: 13px;
  padding: 5px 10px !important;
  margin-right: 0;
}

.sub-menutxt li img {
  height: 14px;
  width: 16px;
}

.sub-menutxt li:hover {
  background: none;
}

.sub-menutxt li:hover a {
  color: #1964af;
}

.loader_page {
  width: 100%;
  height: 100%;
  background: none;
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  z-index: 9999;
}

.loader_page img {
  width: 300px;
}

//aside li:hover .left-menu { left: -15px;  transition: all 800ms ease-in-out; }
aside li.leftmenuclickopen .left-menu {
  left: -15px;
  transition: all 800ms ease-in-out;
}

// aside li .left-submenu { width: 235px; }
aside li:hover .left-submenu {
  width: 455px;
}

.closenewbtnmenu {
  width: 100%;
  margin-right: 2px;
  margin-top: -6px;
  margin-bottom: 10px;
}

.pollbox {
  left: -330px;
  width: 295px;
  bottom: -0px;
  top: inherit;
}

.pollbox h5 {
  font-size: 19px;
  font-weight: bold;
  color: #484848;
}

.btn-poll {
  background: #467fff;
  border-radius: 16px;
  border: none;
  box-shadow: 0 0 0 5px rgba(70, 127, 255, 0.3);
  font-size: 15px;
  padding: 2px 20px;
}

.pollbox .leftmenubox {
  display: block;
  padding: 15px 10px 15px 15px;
  text-align: left;
  border: 1px solid #c4dcec;
}

.pollbox h4 {
  margin: 15px 10px 15px 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #000;
}

.pollbox .team-multiple .team-profile {
  border: 1px solid #fff !important;
}

.helpbox {
  left: -555px;
  width: 515px;
  bottom: 0px;
  top: inherit;
}

.helpbox .leftmenubox {
  border: 1px solid #c4dcec;
  padding: 15px;
  display: block;
}

.helpbox .helpboxTxt {
  background: #f1f5ed;
  padding: 15px 20px;
  text-align: left;
  border-radius: 12px;
}

.helpbox .helpboxTxt h4 {
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  background: #88b17c;
  padding: 4px 20px;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 19px;
}

.helpbox .helpboxTxt ul {
  text-align: left;
}

.helpbox .helpboxTxt li {
  font-size: 13px;
  padding: 0 !important;
  text-align: center;
  display: inline-block;
  margin-right: 30px;
}

.helpbox .helpboxTxt li img {
  display: inline-block;
  margin-right: 0;
}

.helpbox .helpboxTxt li a {
  color: #000;
}

.helpbox .helpboxTxt li:hover {
  background: none;
}

.helpbox .helpboxTxt li:hover a {
  color: #1964af;
}

.helpbox .helpboxTxt li span {
  display: block;
}

.officeappbox {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  width: 67px;
  background: #ffffff;
  border-radius: 12px;
  position: fixed;
  bottom: 10px;
  z-index: 9999;
  text-align: center;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  right: -200px;
}

.officeboxapphover .officeappbox {
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  right: 17px;
  transform: rotateX(0deg);
}

.officeappbox ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.officeappbox ul li {
  padding: 15px 10px;
}

.officeappbox ul li img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.officesliders {
  text-align: center;
  padding: 35px 0 !important;
}

.officesliders img {
  display: inline-block;
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.blackoverlay {
  display: none;
}

.usermyprofilesettingshow .blackoverlay,
.officeboxapphover .blackoverlay {
  content: "";
  display: block;
  z-index: 99999;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  transition: 0.3s;
}

.leftmenuclickopen .blackoverlay {
  content: "";
  display: block;
  z-index: -1;
  cursor: auto;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  transition: 0.3s;
}

/*  Header css */
header {
  background: #ffffff;
  box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: relative;
  margin-top: 18px;
  padding: 7px 14px;
  top: 0;
  z-index: 99999;
}

.darkbtn {
  color: #6e6b7b;
  display: inline-block;
  cursor: pointer;
}

.darkbtn img {
  width: 26px;
}

.input-group {
  background: #f2f2f2;
  border: 1px solid rgba(74, 155, 212, 0.3);
  border-right: none;
  border-radius: 10px;
}

.input-group i {
  color: #6e6b7b;
}

.input-group-text {
  background: none;
  border: none;
}

.input-group .form-control {
  background: none;
  box-shadow: none;
  border: none;
}

.input-group-text:last-child {
  border: 1px solid #c0d8e9;
  border-radius: 10px;
}

.modulesearchbox {
  width: 1%;
  min-width: 0;
  flex: 1 1 auto;
}

.modulesearchbox .wrapper {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.modulesearchbox > div {
  height: 38px !important;
}

.wrapper > div {
  min-height: 38px !important;
}

.search-icon {
  margin: 0 0 0 9px !important;
}

.wrapper > div:nth-child(2) {
  border: 0;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  border-radius: 0.25rem;
  background-color: #fff;
  width: 194px;
  margin-left: 33px;
}

.modulesearchbox .ellipsis a {
  font-size: 12px;
  display: block;
  width: 100%;
  padding: 0.25rem 0;
  clear: both;
  font-weight: 700;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
}

.wrapper > div:nth-child(2) > div.line {
  display: none;
}

.wrapper > div:nth-child(2) > ul {
  padding: 0 !important;
}

.wrapper > div:nth-child(2) ul li {
  padding: 0 !important;
  border-bottom: #eee thin solid;
}

.wrapper > div:nth-child(2) ul li .search-icon {
  display: none;
}

.wrapper > div:nth-child(2) > ul > li > div {
  margin-left: 8px !important;
}

.clear-icon > svg {
  width: 15px;
}

.clear-icon {
  margin: 0px 5px 0 0 !important;
}

.wrapper > div > input {
  color: #495057;
  font-size: 15px;
}

.checkinbox {
  background: rgba(74, 155, 212, 0.15);
  border-radius: 10px;
  padding: 0 12px;
  width: 230px;
}

.checkinbox p {
  margin: 0 0 2px;
  color: #4a9bd4;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
}

.checkinbox label {
  margin: 0;
  color: #4a9bd4;
  font-size: 11px;
  display: block;
  line-height: 11px;
}

.checkinbox h5 {
  margin: 0;
  color: #959595;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
}

.topheadusernot {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: end;
  align-items: center;
}

.topheaduser {
  padding: 0;
  border: none;
  text-align: right;
}

.topheaduser h2 {
  font-weight: 400;
  font-size: 15px;
}

.notifyicon {
  font-size: 23px;
  color: #6e6b7b;
  margin-right: 30px;
  position: relative;
  line-height: 29px;
}

.notifyicon span {
  position: absolute;
  top: 0;
  background: red;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 13px;
  left: 10px;
}

.notificationbox {
  position: absolute;
  width: 340px;
  background: #fff;
  right: 99px;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  top: 90%;
  z-index: 99999;
}

.notificonhoverbox:hover .notifyicon {
  color: #1964af;
  cursor: pointer;
}

//.notificonhoverbox:hover .notificationbox, .usermyprofilesettingshow .notificationbox { transition: .3s; opacity:1; visibility:visible; top:90%; transform: rotateX(0deg); }
.usermyprofilesettingshow .notificationbox {
  transition: 0.3s;
  opacity: 1;
  visibility: visible;
  top: 90%;
  transform: rotateX(0deg);
}

.notificationbox .input-group {
  background: none;
  border: none;
}

.notificationbox .input-group img {
  width: 82%;
}

.notificationbox .input-group-text:first-child {
  padding-right: 0;
}

.notificationbox .input-group-text:last-child {
  border: none;
}

.notificationbox .input-group-text h6 {
  font-size: 14px;
  text-decoration: underline;
  color: #000;
  font-weight: bold;
  margin-top: 0px;
}

.notifytabs .nav-tabs {
  padding-left: 15px;
  margin-top: 10px;
}

.notifytabs .nav-tabs .nav-link {
  color: #bababa;
  background: none;
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding: 4px 10px;
}

.notifytabs .nav-tabs .nav-link i {
  margin-right: 5px;
}

.notifytabs .badge {
  background: #f1f1f1 !important;
  border-radius: 5px;
  color: #adadad;
  margin-left: 5px;
  font-size: 11px;
}

.notifytabs .nav-tabs .nav-link:hover {
  border: none !important;
  color: #000;
}

.notifytabs .nav-tabs .nav-link.active,
.notifytabs .nav-tabs .nav-link.active:hover {
  color: #0195d4;
  border-bottom: 2px solid #0195d4 !important;
}

.notifytabs .tab-content {
  padding: 15px 15px 8px;
}

.notftsection {
  display: flex;
  align-items: flex-start;
}

.notftsection img {
  display: flex;
  align-items: center;
}

.notifyimage {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: #f2f2f2;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  color: #afafaf;
  position: relative;
}

.notifyimage img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
}

.notftsection h4 {
  margin: 0 0 0 10px;
  font-size: 15px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.notftsection h4 span {
  font-size: 13px;
  font-weight: normal;
  color: #4e4e4e;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.notftsection p {
  margin: 0 0 0 10px;
  font-size: 13px;
  font-weight: normal;
  color: #afafaf;
}

.notftsection {
  border-bottom: #eee thin solid;
  padding: 10px 0;
  margin-bottom: 0px;
}

.no-read {
  background: #eee;
  padding: 10px 5px;
  border-bottom: #d7d7d7 thin solid;
}

.notofyscrollsec {
  height: 300px;
  overflow-y: scroll;
}

.activeiconuser {
  position: absolute;
  right: 0;
  bottom: 2px;
  font-size: 10px;
  border-radius: 50%;
  color: #34a853;
}

.notofyscrollsec .activeiconuser {
  bottom: -2px;
}

.usermyprofile {
  cursor: pointer;
}

.usermyprofile .notificationbox {
  right: 10px;
  width: 230px;
  border-radius: 5px;
}

.usermyprofile ul {
  padding: 10px 10px;
  margin: 0;
  list-style: none;
}

.usermyprofile li {
  cursor: pointer;
  margin: 0 0 3px !important;
  font-size: 17px;
  color: #969696;
  display: flex;
  align-items: center;
  padding: 8px 7px;
  justify-content: space-between;
}

.usermyprofile li a {
  font-size: 16px;
  color: #969696;
}

.usermyprofile li:hover,
.usermyprofile li:hover a {
  color: #1964af;
}

.usermyprofile li img {
  margin-right: 14px;
  width: 24px;
}

.usermyprofile li i {
  float: right;
  font-size: 12px;
}

.usermyprofile li:last-child img {
  margin-left: 5px;
  margin-right: 11px;
  width: 22px;
}

.usermyprofile li:last-child {
  margin: 0 !important;
  background-color: #4a9bd4;
  border-radius: 5px;
}

.usermyprofile li:last-child:hover {
  background-color: #1964af;
}

.usermyprofile li:last-child a {
  color: #fff;
}

.delegatedropdwon {
  position: relative;
}

.delegatedropdwon .notificationbox {
  top: 30px;
  left: 0;
  border: 1px solid #c4dcec;
  border-radius: 10px;
  width: 290px;
  padding: 15px 20px;
}

.delegatedropdwon .mandtop-user {
  justify-content: flex-start;
  text-align: left;
  border-radius: 0;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.delegatedropdwon .mandtop-user img {
  margin-left: 0;
  margin-right: 15px;
  border: none;
}

.delegatedropdwon .mandtop-user h2 {
  text-align: left;
  margin-left: 0;
  font-weight: bold;
  max-width: 150px;
}

.delegatedropdwon h4 {
  font-weight: 700;
  color: #4a9bd4;
  font-size: 18px;
  margin-bottom: 15px;
}

.delegateusertop .accordion-button {
  background: #ffffff;
  background-image: none !important;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0;
  padding: 6px;
  border: #fff thin solid;
  border: 1px solid #4a9bd4;
}

.delegateusertop .accordion-button.collapsed {
  border: #fff thin solid;
}

.delegateusertop .mandtop-user {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.delegateusertop .mandtop-user h2 {
  color: #252525;
  font-size: 16px;
}

.delegateusertop .mandtop-user p {
  color: #252525;
  line-height: 22px;
  font-weight: 300;
}

.delegateusertop .accordion-body {
  padding: 0 !important;
  border: none !important;
}

.delgatemoduassign .suggestions-listdelg {
  width: 100%;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  z-index: 1;
  border-radius: 5px;
  list-style: none;
  max-height: 120px;
  max-width: 250px;
  overflow-y: auto;
  padding: 5px 0 0;
}

.delgatemoduassign .suggestiondelg {
  color: #252525 !important;
  background: none;
  padding: 3px 10px !important;
  background: none !important;
  margin-bottom: 0 !important;
}

.delgatemoduassign .suggestiondelg a {
  color: #252525 !important;
  font-size: 15px;
}

.delgatemoduassign .suggestiondelg a:hover {
  color: #1964af !important;
}

.delegateusertop .accordion-item {
  margin-bottom: 12px;
}

.delegateusertop .accordion-item:last-child {
  margin-bottom: 0px;
}

/* home page */

.mainpage-head {
  color: #737373;
  font-weight: 700;
  font-size: 14px;
  margin-top: 15px;
}

.homenews-bg {
  background: linear-gradient(
    180deg,
    rgba(74, 155, 212, 0.2) 0%,
    rgba(74, 155, 212, 0.2) 100%
  );
  border: 1px solid #c4dcec;
  border-radius: 10px;
  padding: 15px;
  height: calc(100% - 44px);
}

.homenewsdesign-bg {
  background: linear-gradient(180deg, #ffffff 0%, #f3faff 100%);
  border: 2px solid #c4dcec;
  border-radius: 10px;
  padding: 15px 15px 12px;
  height: calc(100% - 44px);
}

.homenesnew-bg .tab-content {
  background: linear-gradient(180deg, #ffffff 0%, #f3faff 100%);
  border: 2px solid #c4dcec;
  border-radius: 10px;
  padding: 15px 20px 12px;
  height: 192px;
}

.home-newsimg {
  width: 100%;
  height: 115px;
  object-fit: cover;
  object-position: top;
  border-radius: 6px;
}

// .homenewstxt p { font-size: 12px; line-height: 17px; margin: 0; display: -webkit-box; -webkit-line-clamp: 3; overflow: hidden; -webkit-box-orient: vertical;}
// .homenewstxt h6 { font-size: 10px; line-height: 17px; color: #848484; margin: 0;}
.homenesnew-bg h4 {
  font-weight: bold;
  font-size: 16px;
  color: #484848;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.homenewstxt p {
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  margin-top: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.homenewstxt h6 {
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  color: #848484;
  margin: 0;
}

.noticeheading h5 {
  font-size: 20px;
  border-bottom: #4a9bd4 2px solid;
  padding-bottom: 10px;
  border-radius: 0 0 2px 2px;
  font-weight: bold;
  line-height: 17px;
  color: #484848;
  margin: 0 0 15px;
}

.noticetxtnew {
  line-height: 22px;
  font-size: 15px;
  margin: 0;
}

.newsnewheaidng {
  font-size: 12px;
  color: #4a9bd4;
  font-weight: 700;
  margin: 0;
}

.badge-purple {
  background: rgba(125, 65, 245, 0.3);
  font-size: 10px;
  border-radius: 6px;
  color: #7367f0;
  display: block;
  line-height: normal;
  padding: 4px 7px;
  height: 23px;
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slick-slide > div {
  padding: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-dots {
  bottom: 0;
  position: relative;
  margin-top: 0px;
}

.slick-dots li {
  width: 8px;
  height: 8px;
  margin: 0 4px;
}

.slick-dots li button {
  width: 8px;
  height: 8px;
  padding: 0;
  background: rgba(185, 185, 195, 0.6);
  border-radius: 50%;
}

.slick-dots li button:before {
  display: none;
}

.slick-dots li.slick-active button {
  background: #4a9bd4;
}

.slick-dots li:hover button {
  background: #4a9bd4;
}

.videopopup .modal-header {
  padding: 0 !important;
  border: none !important;
}

.videopopup .modal-content {
  padding: 0;
  border: none;
  border-radius: 0;
}

.videopopup .modal-body {
  padding: 0;
  border: none;
  background: #000;
}

.videopopup video {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
}

.approval-bg {
  background: #ffffff;
  border: 2px solid #c9e1f2;
  border-radius: 10px;
}

.btn-create {
  border-radius: 5px;
  border: 1px solid #c9e1f2;
  background: #fff;
  padding: 3px 9px 3px 15px;
  color: #4a9bd4;
  font-weight: bold;
  margin-bottom: 7px;
}

.btn-create img {
  margin-left: 10px;
}

.approval-bg .nav-tabs {
  border-bottom: 1px solid #c9e1f2;
  padding: 0 10px;
}

.approval-bg .nav-tabs .nav-item {
  margin-right: 25px;
}

.approval-bg .nav-tabs .nav-link {
  color: #0195d4;
  font-weight: 700;
  border-radius: 0;
  font-size: 12px;
  background: none;
  padding: 7px 10px 7px 0;
  border: none;
  text-transform: uppercase;
}

.approval-bg .nav-tabs .nav-link span {
  margin-left: 7px;
}

.approval-bg .nav-tabs .nav-link.active {
  color: #0195d4;
  border: none;
  border-bottom: 2px solid #4a9bd4;
  background: none;
}

.approval-bg .nav-tabs .nav-link:hover {
  color: #333;
  border: none;
}

.approval-bg .nav-tabs .nav-link.active:hover {
  color: #0195d4;
  border: none;
  border-bottom: 2px solid #4a9bd4;
  background: none;
}

.approval-bg .tab-content {
  padding: 22px 23px;
}

.purchaseinnertabs {
  margin-top: 0px;
}

.purchaseinnertabs .nav-tabs {
  padding: 0 25px;
}

span.numberpurchse {
  color: #0195d4;
  text-align: center;
  font-weight: bold;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #def1ff;
  display: inline-block;
  font-size: 13px;
  float: right;
  line-height: 22px;
}

span.numberpurchse.active {
  color: #fff;
  background-color: #0195d4;
  font-weight: normal;
}

.purchaseinnertabs .nav-tabs .nav-link {
  color: #999;
  text-transform: none;
}

.purchaseinnertabs .tab-content {
  padding: 10px 0px;
}

.biginnertabs {
  margin-top: 20px;
}

.biginnertabs .nav-tabs .nav-link {
  font-size: 19px;
  text-transform: none;
  padding: 2px 10px 4px 0;
}

.biginnertabs .nav-tabs {
  padding: 0 !important;
}

.biginnertabs .tab-content {
  padding: 30px 0 20px;
}

.aprlTxt h5 {
  max-width: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  font-size: 32px;
  color: #a2a2a2;
  padding: 4px 0px;
  text-align: center;
  border-radius: 7px;
  margin: 13px auto;
  font-weight: 300;
  height: 53px;
  line-height: 45px;
  cursor: pointer;
}

.aprlTxt p {
  margin: 0;
  color: #4a9bd4;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}

.slick-next,
.slick-prev {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background: #fff !important;
  line-height: 25px;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  box-shadow: 1px 4px 31px 5px rgba(0, 0, 0, 0.2);
  opacity: 1;
  z-index: 9999;
}

.slick-next:hover,
.slick-prev:hover {
  opacity: 1;
  background: #fff !important;
}

.slick-next:before,
.slick-prev:before {
  font-family: "Font Awesome 5 Free";
  color: #7d41f5;
  font-size: 9px;
  opacity: 1;
}

.slick-prev:before {
  content: "\f053";
}

.slick-next:before {
  content: "\f054";
}

.slick-next {
  right: -31px;
}

.slick-prev {
  left: -31px;
}

.homenesnew-bg .slick-prev {
  left: -75px;
}

.homenesnew-bg .slick-next {
  right: -26px;
}

.application-bg {
  padding: 0px;
  background: linear-gradient(180deg, #fcfbd2 0%, #caebf6 100%);
  border: 1px solid #c4dcee;
  border-radius: 10px;
}

.applicationTxt {
  background: url(../images/appl-bg.png) no-repeat;
  width: 121px;
  height: 116px;
  text-align: center;
  position: relative;
  background-size: contain;
  background-position: center;
  margin: 6px auto -8px;
}

.applicationTxt img {
  height: 20px;
  display: inline-block;
}

.applicationTxt p {
  margin: 5px 0 10px;
  color: #0195d4;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.apppplinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.application-bg .slick-prev {
  left: -10px;
}

.application-bg .slick-next {
  right: -11px;
}

.application-bg .slick-slide > div {
  padding: 0;
}

.application-bg .slick-list {
  margin: 0;
}

.extapptxt p {
  margin: 0px 0 10px;
  color: #0195d4;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.extapptxtinner img {
  height: 20px;
  display: inline-block;
}

.extapptxtinner span {
  width: 42px;
  height: 42px;
  background: #ebf3ff;
  border: 1px solid #f8e822;
  box-shadow: inset 8px 8px 16px #ffffff,
    inset -8px -8px 16px rgba(148, 169, 203, 0.72);
  border-radius: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.extapptxtinner {
  background: #ebf3ff;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.9),
    10px 10px 20px rgba(180, 199, 213, 0.5), inset -8px -8px 16px #ffffff,
    inset 8px 8px 16px #b4c1d5;
  border-radius: 116px;
  width: 55px;
  margin: 18px auto 12px;
  height: 55px;
  line-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.officebox {
  padding: 25px 0px 4px;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(248, 232, 34, 0.09) 0%,
    rgba(74, 155, 212, 0.09) 100%
  );
  border: 1px solid #c4dcee;
  box-shadow: 3px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  // max-width: 64px;
  margin-left: auto;
  margin-top: 14px;
}

.officebox img {
  width: 50px;
  object-fit: contain;
  margin-bottom: 0px;
}

.officebox span {
  margin: 5px 0 4px;
  color: #0195d4;
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.teamscroll {
  max-height: 440px;
  overflow-y: scroll;
  padding: 0 0px !important;
  margin: 0 25px;
}

.teambg {
  padding: 15px 0;
  background: #fff;
  border-radius: 10px;
}

.teambg-height {
  height: calc(100% - 41px);
}

.teambg .teamheadsection {
  padding: 0 15px;
}

.team-profile {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
}

.team-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.team-profile i {
  position: absolute;
  right: 0;
  bottom: 0px;
  font-size: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
}

.present {
  margin-bottom: 20px;
}

.present:last-child {
  margin-bottom: 0px;
}

.present .team-profile {
  border: 2px solid #34a853;
}

.present .team-profile i {
  color: #34a853;
  background: #34a853;
}

.teamtxt {
  margin-left: 15px;
}

.teamtxt h5 {
  margin: 0 0 5px;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
}

.teamtxt p {
  margin: 0;
  color: #252525;
  font-size: 12px;
  font-weight: 300;
}

.teamtab-labels {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  color: #9d9d9d;
  margin-bottom: 5px;
}

.teamtab-labels div {
  margin-right: 20px;
}

.teamtab-labels span {
  display: inline-block;
  width: 13px;
  height: 13px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border: #fff 2px solid;
  margin-right: 5px;
}

.teamtab-labels .present {
  background: #34a853;
}

.teamtab-labels .absent {
  background: #0096ff;
}

.teamtab-labels .no-punch {
  background: #f8e822;
}

.teamtab-labels .holidayweek {
  background: #666666;
}

.present h6 {
  color: #34a853;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}

.badge-light-purple {
  background: rgba(115, 103, 240, 0.25);
  font-size: 13px;
  border-radius: 5px;
  color: #252525;
  display: inline-block;
  line-height: normal;
  padding: 4px 18px;
}

.teamtime {
  margin: 0;
  color: #252525;
  font-size: 11px;
  font-weight: 400;
}

.absent .team-profile {
  border: 2px solid #0096ff;
}

.unknwn .team-profile {
  border: 2px solid #f8e822;
}

.WeekOff .team-profile {
  border: 2px solid #666666;
}

.absent .team-profile i {
  color: #0096ff;
  background: #0096ff;
}

.unknwn .team-profile i {
  color: #f8e822;
  background: #f8e822;
}

.hld .team-profile {
  border: 2px solid #000;
}

.WeekOff .team-profile i {
  color: #666666;
  background: #666666;
}

.absent h6 {
  color: #ff0000;
}

.unknwn {
  margin-bottom: 20px;
}

.travel .team-profile {
  border: 2px solid #4a9bd4;
}

.travel .team-profile i {
  color: #4a9bd4;
  background: #4a9bd4;
}

.travel h6 {
  color: #4a9bd4;
}

.default .team-profile {
  border: 2px solid #2e2e2e;
}

.default .team-profile i {
  color: #4a9bd4;
  background: #2e2e2e;
}

.default h6 {
  color: #2e2e2e;
}

.bottomboxbg {
  background: #ffffff;
  border: 1px solid #c4dcec;
  border-radius: 10px;
}

.traitabs {
  text-align: center;
}

.traitabs .nav-tabs {
  justify-content: center;
  border: none;
  background: rgba(74, 155, 212, 0.3);
  border-radius: 68px;
  display: inline-flex;
}

.traitabs .nav-tabs .nav-link {
  color: #4a9bd4;
  font-size: 14x;
  font-weight: bold;
  padding: 6px 25px;
  background: none;
  border: none;
}

.traitabs .nav-tabs .nav-link.active {
  color: #fff;
  background: #4a9bd4;
  border-radius: 68px;
}

.homenesnew-bg {
  text-align: left;
}

.homenesnew-bg .tab-content {
  text-align: left;
}

.homenesnew-bg .nav-tabs {
  background: #dadada;
  border-radius: 40px;
  padding: 3px 3px 4px 3px;
  margin: 4px 0 6px;
}

.homenesnew-bg .nav-tabs .nav-link {
  font-size: 11px;
  font-weight: normal;
  padding: 0px 15px;
  color: #717171;
  line-height: 22px;
}

.homenesnew-bg .nav-tabs .nav-link.active {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
  border-radius: 40px;
}

.homcousesecnew {
  height: 303px;
  overflow-y: scroll;
  margin-top: 20px;
}

.homecoussec {
  text-align: left;
  border: 1px solid #dfe9f0;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.homecoussec:last-child {
  margin-bottom: 0px;
}

.homecoussec img {
  height: 70px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.homecoussec h4 {
  margin: 0;
  color: #5c4f4f;
  font-size: 14px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.homecoussec h5 {
  margin: 0 0 5px;
  color: #919191;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.homecoussec h6 {
  margin: 0;
  color: #939393;
  font-size: 11px;
}

.homecoussec h6 i {
  margin: 0;
  color: #000;
}

.homecoussec span {
  background: rgba(74, 155, 212, 0.2);
  border-radius: 10px;
  color: #0194d2;
  font-size: 11px;
  padding: 2px 8px;
}

.homecoussec span.warning {
  background: rgba(255, 122, 0, 0.2);
  color: #ff7a00;
}

.homecoussec span.danger {
  background: rgba(255, 0, 0, 0.2);
  color: #ff0000;
}

.timescrollsec {
  max-height: 360px;
  overflow-y: scroll;
}

.timelines {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.timelines:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: #ff0000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 5px;
}

.timelines:nth-child(2n + 2):after {
  background: #0194d2;
}

.timelines:nth-child(3n + 3):after {
  background: #ff7a00;
}

.timelines:before {
  content: "";
  position: absolute;
  left: 4px;
  right: 0;
  background: #e1e1e1;
  width: 1px;
  height: 100%;
  top: 5px;
}

.timelines:last-child {
  margin-bottom: 0px;
}

.timelines h4 {
  margin: 0 0 1px;
  color: #4e4e4e;
  font-size: 16px;
  font-weight: bold;
}

.timelines span {
  color: #939393;
  font-size: 13px;
  font-weight: 400;
  float: right;
}

.timelines h5 {
  margin: 0 0 15px;
  color: #939393;
  font-size: 15px;
  white-space: inherit;
}

.timelinecontent-sec {
  font-size: 15px;
  font-weight: bold;
  color: #4e4e4e;
}

.timelinecontent-sec .team-profile {
  width: 37px;
  height: 37px;
}

.team-multiple .team-profile {
  margin-right: -15px;
  border: 2px solid #fff;
}

.team-multiple .team-profile:last-child {
  margin-right: 0px;
  background: #eee;
  border: 2px solid #fff;
}

.team-multiple .team-profile:last-child h5 {
  text-align: center;
  font-size: 18px;
  line-height: 35px;
  font-weight: bold;
}

.birthday-bg {
  background: rgba(74, 155, 212, 0.15);
  border: 1px solid #c4dcec;
  border-radius: 10px;
  position: relative;
}

.birhead {
  padding: 15px;
}

.birhead h4 {
  margin: 0 0 2px 15px;
  color: #0194d2;
  font-size: 19px;
  font-weight: bold;
}

.birhead h5 {
  margin: 0 0 0px 15px;
  color: #5c5c5c;
  font-size: 13px;
  font-weight: normal;
}

.brthbgimage {
  position: absolute;
  right: 0;
  top: -15px;
}

.birgday-listing {
  height: 315px;
  overflow-y: scroll;
}

.birgday-listing {
  padding: 0 15px !important;
}

.birgday-listing .btn {
  padding: 4px 10px;
  font-size: 11px;
  min-width: 55px;
}

.birgday-listing .btn:disabled {
  opacity: 0.6;
}

.birgday-listing .team-profile {
  width: 35px;
  height: 35px;
}

.birgday-listing .teamtxt h5 {
  color: #4a9bd4;
  font-weight: bold;
  margin-bottom: 2px;
  max-width: 150px;
}

.birgday-listing .teamtxt p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
}

.birgday-listing h6 {
  color: #5c5c5c;
  margin: 0 0 4px;
  font-size: 12px;
  font-weight: bold;
}

.birgday-listing td {
  padding: 0;
  border: none;
  vertical-align: middle;
  padding-bottom: 15px;
}

.birgday-listing td:last-child {
  text-align: right;
}

.anniversry-bg {
  background: #f3f3e3;
  border-color: #ddd790;
}

/* cms news  */

.innerheadsec {
  padding: 10px 35px 20px;
}

.inner-page-title {
  color: #0195d4;
  font-size: 25px;
  font-weight: bold;
  margin-top: 0;
}

.breadcrumb {
  padding: 0;
  background: none;
  font-size: 18px;
}

.breadcrumb li a {
  color: #5c4f4f;
}

.breadcrumb-item.active,
.breadcrumb-item.active::before {
  color: #0194d2;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 11px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f054";
  font-weight: 900;
  padding-right: 11px;
  font-family: "Font Awesome 5 Free";
  font-size: 11px;
  position: relative;
  top: 6px;
}

.table-search {
  background: #f1f1f1;
  border-radius: 5px;
  border: none;
}

.table-search input {
  padding-left: 0;
}

.table-search input::-webkit-input-placeholder {
  font-style: italic;
}

.table-search input::-moz-placeholder {
  font-style: italic;
}

.table-search input:-ms-input-placeholder {
  font-style: italic;
}

.inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.btn-betweenspaing .btn {
  margin-left: 20px;
}

.btn-primary-inner.dropdownbtn {
  border: 1px solid #4a9bd4;
  background: none;
  color: #4a9bd4;
}

.btn-primary-inner.dropdownbtn:hover {
  background: none;
  border-color: #0062a9;
  color: #0062a9;
}

.dropdown-toggle {
  align-items: center;
  display: flex;
}

.dropdown-toggle::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  border: none;
  border: none;
  position: relative;
  top: 1px;
  font-size: 13px;
  left: 8px;
}

.dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  top: 0% !important;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  min-width: 8.5rem;
}

.dropdown-item {
  font-size: 16px;
  color: #666666;
  padding: 5px 15px;
}

.dropdown-item i {
  margin-right: 10px;
  width: 14px;
}

.dropdown:hover .dropdown-menu {
  transition: 0.3s;
  opacity: 1;
  visibility: visible;
  top: 0% !important;
  transform: rotateX(0deg);
}

.dropdown-item.active,
.dropdown-item:active {
  background: #4a9bd4;
}

.tableaction .btn-primary {
  border: none;
  padding: 0;
  background: none !important;
}

.tableaction .dropdown-toggle:after {
  content: "";
}

.tableaction:hover .dropdown-menu {
  top: 0px !important;
}

.tableaction .dropdown-item {
  border-bottom: #eee thin solid;
}

.tableaction .dropdown-item:last-child {
  border-bottom: none;
}

.tableaction .dropdown-menu {
  left: auto !important;
  right: 0 !important;
}

.tablecured thead th {
  vertical-align: middle;
  background: #0195d4;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}

.tablecured thead th.unsortcls {
  cursor: pointer;
  background: #0195d4 url(../images/sort.png) no-repeat 95% center;
}

.tablecured thead th.asccls {
  cursor: pointer;
  background: #0195d4 url(../images/sortas.png) no-repeat 95% center;
}

.tablecured thead th.desccls {
  cursor: pointer;
  background: #0195d4 url(../images/sortds.png) no-repeat 95% center;
}

.tablecured tr td,
.tablecured tr th {
  border-bottom: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}

.tableremovetdoverflow tr td {
  overflow: inherit;
}

.tablecured tr td {
  color: #6e6b7b;
  font-size: 15px;
  vertical-align: middle;
}

.tablecured tr td .badge {
  color: #fff;
  font-size: 13px;
  font-weight: normal;
}

.tablecured tr td:last-child {
  overflow: inherit;
}

.tablecured tr th:last-child {
  background: #0195d4;
}

.tablecured.tablcuredlastnew tr td:last-child {
  overflow: hidden;
}

.tablecured tr td:last-child img {
  cursor: pointer;
}

.tablecured tr td.text-theme {
  color: #4a9bd4;
  font-size: 15px;
}

.tablecured {
  border: #dee2e6 1px solid !important;
}

.tablecured .logCheck {
  margin-bottom: 0;
  height: 18px;
  width: 18px;
  padding: 0;
}

.tablecured .checkmark {
  width: 18px;
  height: 18px;
}

.tablecured .logCheck .checkmark:after {
  left: 6px;
  top: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: rgba(1, 149, 212, 0.1);
}

.sortinglist {
  border: 1px solid #0195d4;
  border-radius: 5px;
  color: #6e6b7b;
  padding: 6px 10px 6px 20px;
  font-size: 17px;
  display: inline-flex;
  margin-left: 15px;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url(../images/select-drop.png) no-repeat right center;
}

.newbgselect {
  background: url(../images/select-drop.png) no-repeat 97% 11px #fff;
}

.sortinglist select {
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  color: #b9b9c3;
  width: 53px;
  margin-left: 15px;
  padding: 0;
  height: 23px;
  padding-left: 4px;
  text-align: left !important;
}

.sortinglist select:focus {
  box-shadow: none;
}

.pagination .visually-hidden {
  display: none;
}

.pagination .page-item + .page-item {
  margin-left: 10px;
}

.page-item .page-link {
  background: none;
  border: 1px solid #0195d4;
  border-radius: 4px;
  color: #0195d4;
  font-size: 16px;
  font-weight: bold;
  padding: 6px 12px;
}

.page-item.active .page-link {
  background: #0195d4;
  border: 1px solid #0195d4;
  color: #fff;
}

.previpag .page-link {
  padding: 5px 12px 4px 12px;
}

.previpag .page-link span {
  font-size: 30px;
  line-height: 17px;
  font-weight: bold;
  color: #c4cdd5;
}

.page-item:hover .page-link {
  background: #0195d4;
  border: 1px solid #0195d4;
  color: #fff;
}

.pagination {
  justify-content: end;
  margin-right: 15px;
}

.modal-backdrop {
  z-index: 999999;
}

.modal {
  z-index: 9999999999999;
}

/* right poup */
.filter-popup {
  padding-right: 0 !important;
}

.filter-popup.modal.fade .modal-dialog {
  -webkit-transform: translate(100%) scale(1);
  transform: translate(100%) scale(1);
  margin: 0 0 0 auto;
  min-height: 100%;
}

.filter-popup.modal.fade.show .modal-dialog {
  -webkit-transform: translate(0);
  transform: translate(0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
}

.filter-popup .modal-content {
  border-radius: 0;
  border: none;
}

.filter-popup .modal-header {
  border: none;
  justify-content: center;
}

.filter-popup .modal-title {
  font-size: 27px;
  font-weight: bold;
  color: #0195d4;
}

@media (min-width: 576px) {
  .filter-popup .modal-dialog {
    max-width: 400px;
  }

  .maxwidth90 .modal-dialog {
    max-width: 80%;
  }
}

.mobilmenupoup .modal-dialog {
  max-width: 100%;
}

.modaldefaultclose .btn-close {
  position: absolute;
  z-index: 1111;
  right: 10px;
  top: 10px;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  background-color: #fff;
  padding: 14px;
  border: 0;
  border-radius: 0.375rem;
  opacity: 1;
}

.modaldefaultclosecenter .btn-close {
  position: absolute;
  top: -15px;
  right: -15px;
  border-radius: 50%;
  border: 1px solid rgba(74, 155, 212, 0.6);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  background-size: 10px;
}

.modaldefaultclose .btn-close:hover {
  opacity: 0.8;
}

.modaldefaultclosecenter .btn-close:hover {
  opacity: 1;
}

.modal-footer {
  justify-content: center;
}

.modal-dialog button + button {
  margin-left: 15px;
}

.modaldefaultclosecenter .modal-header {
  border-bottom: 1px solid rgba(74, 155, 212, 0.5);
  justify-content: center;
  background: rgba(217, 239, 248, 0.31);
}

.modaldefaultclosecenter .modal-title {
  font-size: 24px;
  font-weight: bold;
  color: #0195d4;
}

.withouthead .modal-header {
  padding: 0;
  border: none;
}

/* popupdesigns */

.innergroup .form-control {
  border: 1px solid #d8d6de;
  color: #000;
  border-radius: 10px;
  font-size: 15px;
  height: 40px;
  font-weight: bold;
}

.innergroup select.form-control option {
  color: #000;
}

.innergroup .form-control:focus {
  border: 1px solid #4285f4;
  box-shadow: none;
}

.innergroup textarea.form-control {
  height: 100px;
}

.innergroup label {
  font-size: 14px;
  margin-bottom: 1px;
  color: #5e5873;
  font-weight: bold;
}

.innergroup p {
  font-size: 13px;
  color: #b9b9c3;
  font-weight: normal;
}

.innergroupPrint p {
  font-size: 13px;
  color: #808080;
  font-weight: normal;
}

.innergroup span {
  font-size: 13px;
  color: #484848;
  font-weight: bold;
}

.innergroup .form-control::-webkit-input-placeholder {
  color: #b9b9c3;
  font-weight: normal;
}

.innergroup .form-control::-moz-placeholder {
  color: #b9b9c3;
  font-weight: normal;
}

.innergroup .form-control:-ms-input-placeholder {
  color: #b9b9c3;
  font-weight: normal;
}

.select__control {
  border-radius: 10px !important;
  min-height: 40px !important;
}

.select__multi-value {
  background: #0194d2 !important;
  color: #fff;
}

.select__multi-value__label {
  color: #fff !important;
}

.css-1s2u09g-control:hover {
  border: 1px solid #4285f4 !important;
  box-shadow: none !important;
}

.css-1pahdxg-control {
  border: 1px solid #4285f4 !important;
  box-shadow: none !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.uploadBtn {
  background: #0194d2;
  border-color: #0194d2;
  color: #ffffff;
  border: none;
  outline: none;
  text-align: center;
  font-size: 16px;
  border-radius: 0 10px 10px 0;
  padding: 8px 18px;
  height: 40px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: -124px;
  top: 0;
  opacity: 0;
  cursor: pointer;
  height: 500px;
  font-size: 20px;
  width: 900px;
}

.up-loposition {
  position: absolute;
  right: 0px;
  top: 25px;
}

.news-detail h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 15px 0;
}

.news-detail h4 {
  font-size: 20px;
  font-weight: normal;
  color: #484848;
}

.news-detail img {
  border-radius: 10px;
  border: 1px solid #4a9bd4;
}

.news-detail p {
  font-size: 18px;
  font-weight: normal;
  color: #a4a4a4;
  line-height: 27px;
  margin-bottom: 25px;
}

.news-detail-marq img {
  border-radius: 0;
  border: none;
}

/* user */
.userboxes {
  border-radius: 0;
  padding: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  border: #eee thin solid;
}

.userboxes h5 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  color: #5a5a5a;
}

.userboxes p {
  font-size: 16px;
  font-weight: normal;
  color: #7367f0;
  margin-bottom: 0;
}

.user-avtar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(115, 103, 240, 0.12);
  line-height: 50px;
  text-align: center;
}

.user-avtar i {
  font-size: 20px;
  color: #7367f0;
}

.adminboxes .userboxes {
  margin-bottom: 15px;
}

.avtardanger p,
.avtardanger .user-avtar i {
  color: #ea5455;
}

.avtarsuccess p,
.avtarsuccess .user-avtar i {
  color: #28c76f;
}

.avtarwarning p,
.avtarwarning .user-avtar i {
  color: #ff9f43;
}

.avtardanger .user-avtar {
  background: rgba(234, 84, 85, 0.12);
}

.avtarsuccess .user-avtar {
  background: rgba(40, 199, 111, 0.12);
}

.avtarwarning .user-avtar {
  background: rgba(255, 159, 67, 0.12);
}

.tablecured .teamtxt h5 {
  margin: 0 0 15px;
  color: #939393;
  font-size: 16px;
  white-space: inherit;
  max-width: 130px;
}

.tablecured .teamtxt p {
  font-size: 13px;
  color: #8b8b8b;
}

.bad-status {
  border-radius: 25px;
  padding: 6px 12px;
  font-weight: 700 !important;
  font-size: 13px;
}

.bad-status.badge-danger {
  background: #f252521a;
  color: #f25252 !important;
}

.bad-status.badge-success {
  background: rgba(40, 199, 111, 0.12);
  color: #28c76f !important;
}

.bad-status.badge-warning {
  background: #fbe1af;
  color: #9b7223 !important;
}

.roletoreluser h6 {
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 0;
}

.userboxes .team-multiple .team-profile:last-child h5 {
  font-size: 15px;
}

.btn-blanktd {
  padding: 0;
  box-shadow: none !important;
}

.delteroleop {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.statusroleop {
  position: absolute;
  right: 15px;
  bottom: 47px;
}

.statusroleop .bad-status {
  padding: 4px 11px;
  font-size: 12px;
  line-height: 11px;
}

.btn-blanktd:hover {
  color: #333 !important;
}

.permission-box .logCheck {
  margin-bottom: 0;
  height: 16px;
  line-height: 18px;
  padding-left: 25px;
  font-size: 14px;
  font-weight: normal;
  color: #6e6b7b;
}

.permission-box .checkmark {
  width: 16px;
  height: 16px;
}

.permission-box .logCheck .checkmark:after {
  left: 5px;
  top: 1px;
}

.permission-box {
  border: none;
}

.permission-box td {
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 7px 5px;
  vertical-align: middle;
}

.permission-box h4 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 0;
}

.permission-box h5 {
  font-size: 16px;
  font-weight: bold;
  color: #5e5873;
  margin-bottom: 0;
}

.permission-box h5 i {
  font-size: 14px;
  margin-right: 4px;
}

.permission-box h6 {
  font-size: 14px;
  margin-left: 25px;
  font-weight: bold;
  color: #5e5873;
  margin-bottom: 0;
}

.permission-box h6 i {
  font-size: 12px;
  margin-right: 2px;
}

.perhead h4 {
  font-size: 22px;
  margin-bottom: 0px;
  margin-top: 20px;
}

.perhead p {
  margin: 0;
  color: #a5a5a5;
  margin-bottom: 20px;
  border-bottom: #cbcbcb thin solid;
  padding-bottom: 10px;
}

.otp-input input {
  width: 45px;
  margin-right: 13px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-weight: 700;
  font-size: 24px;
  border-radius: 12px;
  border: #f7f7f7 1px solid;
  background: #f7f7f7;
  outline: none;
  color: #031912;
  margin-top: 10px;
}

.otp-input input:focus {
  border: 1px solid #1964af;
}

.otp-input input::placeholder {
  color: transparent;
}

.otp-input input:not(:placeholder-shown) {
  border: 1px solid #1964af;
}

/* imper */
.user-imageemper {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: #ddd thin solid;
  padding: 4px;
  margin: 20px auto 0;
}

.user-imageemper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.empertitle {
  text-align: center;
  margin: 25px 0;
}

.empertitle h4 {
  color: #4a9bd4;
  font-weight: bold;
  margin-bottom: 10px;
}

.empertitle p {
  color: #666666;
  font-size: 15px;
  margin-bottom: 20px;
}

.img-sectionemper {
  background: #4a9bd4;
  border-radius: 9px;
  text-align: center;
  padding: 10px;
}

.img-sectionemper h4 {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 5px;
}

.img-sectionemper p {
  color: #fff;
  font-size: 13px;
  margin: 0 0 10px;
}

.img-sectionemper .logCheck {
  padding-left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0px;
}

.img-sectionemper .user-imageemper {
  width: 80px;
  height: 80px;
  border: none;
}

.img-sectionemper .checkmark {
  left: inherit;
  right: 20px;
  top: 6px;
  border-radius: 50%;
}

.img-sectionemper .logCheck input ~ .checkmark,
.img-sectionemper .logCheck input:checked ~ .checkmark {
  background: none;
  border: #fff thin solid;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.ticket-dis:disabled,
.form-control[readonly] {
  background-color: #e5e4e2;
  cursor: not-allowed;
}

.uploaded-image {
  border: #eee thin solid;
  position: relative;
  width: 100px;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0;
}

.uploaded-image .btn-close {
  width: 12px;
  height: 12px;
  padding: 9px;
  background-size: 8px;
  border: 1px solid #f44336;
  top: -10px;
  right: -10px;
}

.gradientselect {
  background: linear-gradient(
    180deg,
    rgba(74, 155, 212, 0.8) 0%,
    rgba(248, 232, 34, 0.8) 100%
  );
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
}

.gradientselect .checkmark {
  right: 6px;
  width: 15px;
  height: 15px;
}

.gradientselect1 {
  background: linear-gradient(
    180deg,
    rgba(248, 232, 34, 0.8) 0%,
    rgba(74, 155, 212, 0.8) 100%
  );
}

.gradientselect2 {
  background: linear-gradient(
    180deg,
    rgba(74, 155, 212, 0.6) 0%,
    rgba(74, 155, 212, 0.6) 100%
  );
}

.gradientselect3 {
  background: linear-gradient(180deg, #f3f089 0%, #a7cfdd 100%);
}

.gradientselect .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 9px;
}

.viewformaftersubmit label {
  font-size: 14px;
  color: #959595;
}

.viewformaftersubmit p {
  font-size: 16px;
  color: #525252;
  font-weight: bold;
  border-bottom: #eee thin solid;
  padding-bottom: 5px;
  line-height: 20px;
}
.viewformaftersubmitapproval p {
  font-size: 13px;
  color: #b9b9c3;
  font-weight: normal;
  font-family: "Calibri";
}
.package-section {
  border: none;
  padding: 20px 20px 0;
  background: #ffffff;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  margin-bottom: 20px;
}

.package-section h5 {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  padding-right: 60px;
  overflow: hidden;
  white-space: nowrap;
  color: #0195d4;
  max-width: 82%;
}

.package-section h6 {
  color: #6e6d6d;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.package-section h6:nth-child(3) {
  margin-top: 8px;
}

.pack-details p {
  color: #8a8a8a;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  line-height: 18px;
}

.pack-details h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  padding-right: 0;
  color: #000;
  line-height: 18px;
  white-space: inherit;
}

.cc-image {
  border: #cfcfcf 1px solid;
  padding: 4px;
  box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.1);
}

.cc-image img {
  width: 100%;
  height: 100%;
}

.grid-status {
  position: absolute;
  top: -5px;
  right: 10px;
}

.grid-status a {
  color: #0195d4;
}

.grid-status a:hover {
  color: #999;
}

.downloadtemplate {
  position: relative;
  z-index: 10;
}

.editbtn {
  background: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  color: #0195d4;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  border: none;
}

.drapdroparea,
.notclickdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  min-height: 150px;
  border: 2px dashed #d8d8d8;
  border-radius: 10px;
  padding: 25px 0;
  margin: 20px 0;
  width: 100%;
}

.drapdroparea p,
.notclickdrop p {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #34343d;
}

.drapdroparea input[type="file"] {
  height: 100%;
  z-index: 9;
}

.drapdroparea .btn-primary-inner {
  border-radius: 30px;
}

.drapdroparea:hover .btn-primary-inner {
  background: #36373f;
  border-color: #36373f;
}

.drapdroparea .btn-primary-inner:disabled {
  opacity: 0.4;
}

.uploadrecordstab tr td {
  padding: 5px 10px;
}

.uploadrecordstab tr th {
  padding: 7px 10px;
}

.wishname {
  font-size: 15px;
  width: 100%;
}

.addusertabs > .nav-tabs {
  border: #eee thin dashed;
  background: #eee;
  padding: 10px 30px;
}

.addusertabs > .tab-content {
  margin-top: 15px;
  background: #fff;
  padding: 10px 30px;
}

.addusertabs > .nav-tabs .nav-link {
  border: none;
  background: none;
  color: #727272;
  padding-left: 0;
  padding-right: 10px;
  margin-right: 60px;
  background-color: #eee;
  z-index: 10;
  position: relative;
}

.addusertabs > .nav-tabs .nav-link span {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 5px;
  background: #fff;
  display: inline-block;
  box-shadow: 1px 3px 2px 0px rgba(0, 0, 0, 0.1);
  border: #eee thin solid;
}

.addusertabs > .nav-tabs .nav-item {
  position: relative;
  margin-right: 10px;
}

.addusertabs > .nav-tabs .nav-item::after {
  content: "";
  position: absolute;
  top: 55%;
  height: 4px;
  border-top: #afafaf thin dashed;
  width: 100%;
}

.addusertabs > .nav-tabs .nav-item:last-child:after {
  border: none;
}

.addusertabs > .nav-tabs .nav-link.active,
.addusertabs > .nav-tabs .nav-link:hover {
  color: #0195d4;
}

.addusertabs > .nav-tabs .nav-link.active span {
  color: #fff;
  background: #0195d4;
}

.addusertabs > .btn {
  font-size: 15px;
}

.mobile-country-drop {
  border: #d8d6de thin solid;
  border-radius: 10px;
}

.mobile-country-drop:hover,
.mobile-country-drop:focus {
  border: #4285f4 thin solid;
}

.mobile-country-drop .select__control {
  border: none !important;
  border-radius: 10px 0 0 10px !important;
}

.mobile-country-drop .css-1s2u09g-control:focus {
  border: none !important;
  border-radius: 10px 0 0 10px !important;
}

.mobile-country-drop .form-control {
  border: none !important;
  border-radius: 0 10px 10px 0;
  padding-left: 0px;
}

.inneereye .eyepassword {
  top: 39px;
}

input.nonumbdrop::-webkit-outer-spin-button,
input.nonumbdrop::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"].nonumbdrop {
  -moz-appearance: textfield !important;
}

.countryimageph {
  width: 20px;
}

.policylistnames {
  padding: 25px 0 1px;
  background: #fff;
  border-radius: 5px 0 0 5px;
  margin: 18px 0 15px;
}

.policylistnames li {
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  color: #666666;
  margin-bottom: 15px;
  padding-left: 20px;
  list-style: none;
  cursor: pointer;
  position: relative;
}

.policylistnames a {
  color: #666666;
}

.policylistnames li.active,
.policylistnames li.active a {
  color: #4a9bd4;
  font-size: 20px;
  font-weight: bold;
}

.policylistnames li.active:before {
  position: absolute;
  content: "";
  left: 0;
  width: 4px;
  height: 27px;
  border-radius: 30px;
  background-color: #4a9bd4;
}

.policylistnames li:hover {
  color: #4a9bd4;
}

.policylistnames li:hover a {
  color: #4a9bd4;
}

.policylistnames li.active span.numberpurchse {
  color: #fff;
  background-color: #0195d4;
  font-weight: normal;
}

.policylistnames li span.numberpurchse {
  margin-top: 3px;
}

.content-wrapper-inner2 {
  border-radius: 0 5px 5px 0;
  padding-bottom: 0;
}

.content-wrapper-inner2 .innerheadsec {
  padding: 10px 25px 20px;
}

.policyhead {
  color: #4a9bd4;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  background: rgba(74, 155, 212, 0.1);
  border-left: 3px solid #4a9bd4;
  border-right: 3px solid #4a9bd4;
  padding: 10px 0;
  margin-bottom: 20px;
}

.policy-boxhead {
  background: #ffffff;
  border: 1px solid rgba(74, 155, 212, 0.4);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 15px;
}

.policy-boxhead img {
  margin-right: 12px;
}

.policy-boxhead h4 {
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-bottom: 15px;
  margin-bottom: 11px;
  border-bottom: #eee thin solid;
  padding-bottom: 10px;
}

.policy-boxhead h5 {
  color: #b3b3b3;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.calendar-bg {
  background: #fff;
  border: 1px solid #dbebf6;
  border-radius: 10px;
  padding: 10px;
}

.todobox {
  background: rgba(74, 155, 212, 0.15);
  border-radius: 10px;
  padding: 12px;
}

.todobox h3 {
  font-size: 16px;
  font-weight: bold;
}

.todobox-content {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}

.todobox-content h4 {
  font-size: 15px;
  font-weight: normal;
}

.todobox-content h4 span i {
  font-size: 11px;
  color: #28c76f;
}

.todobox-content p {
  font-size: 14px;
  color: #a3a3a3;
  margin: 15px 0 20px;
  line-height: 18px;
  font-weight: normal;
}

.paperclip {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #000;
}

.todonames span {
  width: 25px;
  height: 25px;
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 25px;
  margin-right: -12px;
}

.todonames span:nth-child(n + 1) {
  background: #0194d2;
}

.todonames span:nth-child(2n + 2) {
  background: #6b12b7;
}

.todonames span:nth-child(3n + 3) {
  background: #00d339;
}

.todonames span:nth-child(4n + 4) {
  background: #ff7a00;
}

.todobox .btn-secondary {
  background: #ffffff;
  border: 1px solid rgba(74, 155, 212, 0.41);
  border-radius: 10px;
  background: #fff;
  color: #b0b0b0;
  display: block;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
}

.holiday-bg {
  margin-bottom: 15px;
  background: #ececb8;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 10px;
}

.holiday-bg h4 {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin: 0;
}

.holiday-bg p i {
  color: red;
}

.holiday-bg p {
  font-size: 12px;
  font-weight: bold;
  color: #4a9bd4;
  margin: 0;
}

.holiday-time {
  text-align: center;
}

.holiday-time h4 {
  font-size: 25px;
  font-weight: bold;
  color: #565656;
  margin: 0;
  line-height: 26px;
}

.holiday-time p {
  font-size: 11px;
  font-weight: bold;
  color: #565656;
  margin: 0;
  margin-bottom: 15px;
}

.holiday-bg1 {
  background: #ececb8;
}

.holiday-bg2 {
  background: #ececb8;
}

.holiday-bg-opt {
  margin-bottom: 15px;
  background: #f3f3e3;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 10px;
}

.holiday-bg-opt h4 {
  font-size: 15px;
  font-weight: bold;
  color: #707070;
  margin: 0;
}

.holiday-bg-opt p i {
  color: red;
}

.holiday-bg-opt p {
  font-size: 12px;
  font-weight: bold;
  color: #4a9bd4;
  margin: 0;
}

.fc-today-button {
  display: none !important;
}

.fc a:hover {
  text-decoration: none;
}

.fc .fc-toolbar-title {
  margin: 0;
  color: #242424;
  font-size: 18px !important;
  font-weight: 700;
  margin-bottom: 0px;
}

.fc .fc-button-primary:disabled {
  display: none;
}

.newclient-headclender h2 {
  position: absolute;
  top: 48px;
}

.fc .fc-daygrid-day-top {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: none !important;
}

.fc .fc-daygrid-day-number {
  color: #242424;
  font-size: 13px;
  font-weight: 400;
  padding: 0px;
  line-height: 23px;
  cursor: pointer;
}

.fc .fc-col-header-cell-cushion {
  color: #000;
  font-weight: 700;
  font-size: 12px;
}

.fc .fc-daygrid-day-frame {
  border-bottom: none;
  margin: 0px;
}

.fc .fc-daygrid-day.fc-day-today {
  background: none !important;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-top {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background: #0194d2 !important;
  border: none;
  border-radius: 8px;
  border-radius: 50%;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-number {
  color: #242424;
  font-weight: bold;
}

.dark-theme .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-number {
  color: #ffffff;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-top {
  width: auto;
  height: auto;
  background: none !important;
}

.checkinCalbox
  .fc
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame
  .fc-daygrid-day-number {
  color: #4a9bd4;
}

.checkinCalbox .fc .fc-daygrid-day-number {
  line-height: normal;
  font-size: 15px;
  color: #000;
  padding: 0;
}

.fc
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame
  .fc-h-event
  .fc-event-main {
  color: #ffffff !important;
}

.fc-h-event {
  background: none;
  border: none;
}

.fc-h-event .fc-event-main {
  color: #51459e !important;
  font-weight: 500;
  font-size: 10px;
}

.fc .fc-daygrid-event {
  text-align: center;
  font-size: 11px;
}

.fc-daygrid-event-dot {
  display: none;
}

.fc .fc-daygrid-day-events {
  margin-top: 0px;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 1rem;
  display: none;
}

.fc .fc-button-primary {
  background-color: #eae8fe;
  border-color: #eae8fe;
  color: #000;
  padding: 2px 11px;
  text-transform: capitalize;
  font-size: 12px;
  border-radius: 10px;
  box-shadow: none !important;
}

.fc .fc-button .fc-icon {
  color: #000000;
}

.fc .fc-button-primary:hover {
  color: #fff;
  background: #b9b9b9;
  border-color: #b9b9b9;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  box-shadow: none;
  background: #4a9bd4;
  border-color: #4a9bd4;
  box-shadow: none;
}

.fc-header-toolbar .fc-toolbar-chunk:first-child button {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  margin: 0;
  margin-left: -4px !important;
  margin-right: 10px;
  font-size: 12px;
}

.fc .fc-button-primary:focus {
  box-shadow: none;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 7px !important;
  border-bottom: 2px solid #c4dcee;
  padding: 0 15px 10px 10px;
}

.fc-media-screen {
  margin: 0 -15px 0 -10px;
}

.calendorbox-border-right {
  border-right: 2px solid #c4dcee;
  margin: -10px 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.calhomtime {
  border-bottom: 2px solid #c4dcee;
  margin: 0 -10px 0 -15px;
  text-align: center;
  height: 58px;
}

.calhomtime h4 {
  font-size: 35px;
  font-weight: bold;
  color: #565656;
  margin: 0;
  line-height: 30px;
}

.calhomtime p {
  font-size: 16px;
  font-weight: 400;
  color: #a0a0a0;
  margin: 0;
}

.event-sectionhome {
  max-height: 446px;
  overflow-y: scroll;
  margin: 0 -10px 0 -15px;
  padding-right: 0 !important;
}

.holidaylistscroll {
  max-height: 188px;
  overflow-y: scroll;
  overflow-x: inherit;
}

.holidaylistscroll .row {
  margin: 0;
}

.holidaylistscroll .row .col-2 {
  padding-left: 0;
}

.holidaylistscroll .row .col-10 {
  padding-right: 0;
}

.calhomeevent {
  margin: 0;
  padding: 10px 10px 0;
}

.fc-eventbox {
  background: #f2f2f2;
  border-left: 5px solid #4a9bd4;
  padding: 10px 10px;
}

.fc-eventbox h4 {
  font-size: 18px;
  text-overflow: ellipsis;
  line-height: 20px;
  overflow: hidden;
  color: #4a9bd4;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}

.fc-eventbox h6 {
  font-size: 13px;
  text-overflow: ellipsis;
  line-height: 20px;
  overflow: hidden;
  color: #1b1e28;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.fc-eventbox h4 strong {
  color: #1b1e28;
}

.fc-eventbox span {
  background: #dbd8ff;
  font-size: 11px;
  font-weight: bold;
  border-radius: 5px;
  color: #5a49ff;
  padding: 5px 8px;
}

.calhomeevent:nth-child(3n + 2) .fc-eventbox {
  border-left: 5px solid #6b12b7;
}

.calhomeevent:nth-child(3n + 3) .fc-eventbox {
  border-left: 5px solid #ff7a00;
}

.meetingnewdesbox {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.meetingnewdesbox h6 {
  font-size: 12px;
  font-weight: bold;
  color: #1b1e28;
  max-width: 130px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.meetingnewdesbox .roletoreluser h5 {
  font-size: 12px;
  font-weight: bold;
  color: #4a9bd4;
  margin-bottom: 0;
  padding: 3px 8px;
  border-radius: 5px;
  background: rgba(74, 155, 212, 0.25);
  display: inline-block;
}

.meetingnewdesbox .team-profile {
  width: 26px;
  height: 26px;
  border: none;
  margin-right: -5px;
}

.meetingnewdesbox .team-profile:last-child {
  background: #b7d4e7;
  border: none;
}

.meetingnewdesbox .team-profile:last-child h5 {
  color: #4a9bd4;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
}

/*----Checkin cal-----*/

.checkinCalbox {
  padding: 13px;
  width: 350px;
  left: 0;
  top: 52px !important;
}

.checkinCalbox .fc .fc-toolbar.fc-header-toolbar {
  border: none;
  padding: 0 10px 7px;
  align-items: end;
}

.checkinCalbox .fc-media-screen {
  margin: 0;
}

.checkinCalbox .fc .fc-button-primary {
  background: none;
  padding: 0 0 0 7px;
  border: none;
  margin-top: -3px;
  font-size: 15px;
  font-weight: bold;
}

.checkinCalbox .fc .fc-button-primary:not(:disabled):active {
  background: none;
  border: none;
}

.checkinCalbox .fc .fc-button-primary span:hover {
  color: #4a9bd4;
}

.checkinCalbox .fc .fc-col-header-cell-cushion {
  color: #4a9bd4;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.checkinCalbox
  .fc
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame
  .fc-daygrid-day-top {
  width: auto;
  height: auto;
  background: none !important;
}

.checkinCalbox
  .fc
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame
  .fc-daygrid-day-number {
  color: #4a9bd4;
}

.checkinCalbox .fc .fc-daygrid-day-number {
  line-height: normal;
  font-size: 15px;
  color: #000;
  padding: 0;
}

.checkinCalbox .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0.5rem;
  display: block;
}

.checkinCalbox
  .fc
  .fc-daygrid-day.fc-day-today
  .fc-daygrid-day-frame
  .fc-h-event
  .fc-event-main {
  color: #000 !important;
}

.checkinCalbox .fc .fc-daygrid-day.fc-day-today {
  cursor: pointer;
}

.checkinCalbox .dotcheckincircle {
  width: 6px;
  height: 6px;
  background: #666666;
  border-radius: 50%;
  margin: 2px auto 8px !important;
  border: none;
}

.checkinCalbox .dotcheckincircle.present {
  background: #35bd59;
}

.checkinCalbox .dotcheckincircle.absent {
  background: #0096ff;
}

.checkinCalbox .dotcheckincircle.today {
  background: #f8e822;
}

.popupcheckintimebox {
  background: rgba(74, 155, 212, 0.1);
  border: 1px solid #4a9bd4;
  border-radius: 10px;
  margin-top: 10px;
  padding: 11px 38px 11px 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupcheckintime {
  display: flex;
  align-items: center;
}

.popupcheckintime img {
  margin-right: 7px;
}

.popupcheckintime h2 {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin: 0;
}

.popupcheckintime p {
  font-size: 12px;
  font-weight: bold;
  color: #4a9bd4;
  margin: 0;
  line-height: 17px;
}

/*-----checkin Cal end-----*/

.addminushtml {
  border: 1px solid #9b9b9b;
  border-radius: 5px;
  background: #fff;
}

.addminushtml input {
  border: 1px solid #9b9b9b !important;
  color: #9b9b9b;
  max-width: 47px;
  text-align: center;
  border-top: none !important;
  border-bottom: none !important;
  height: 23px;
  padding: 0 5px;
  font-size: 13px;
  font-weight: bold;
}

.addminushtml button {
  padding: 0px 7px;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.addminushtml button:last-child {
  border: none !important;
}

.overlay {
  --gradient-color: none !important;
  display: none;
}

.marquee {
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
  color: #292929;
}

.marquee p {
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  padding-right: 10px;
  margin-right: 7px;
}

.marquee p:after {
  content: "";
  position: absolute;
  right: 0;
  top: 9px;
  background: red;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.marquee-container {
  border-top: #4a9bd4 1px solid;
}

.table-responsive {
  scrollbar-color: rgba(24, 47, 81, 0.2) #e4e2e2;
  scrollbar-width: thin;
}

.table-responsive::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #eee;
  border-radius: 5px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(24, 47, 81, 0.2);
  border-radius: 5px;
}

.table-responsive table {
  margin-bottom: 0;
}

.table-responsive {
  margin-bottom: 15px;
}

.viewpo.tablecured tr th,
.viewpo.tablecured tr td {
  font-size: 13px;
  padding: 7px 10px;
}

.analytic-content {
  background: #ffffff;
  padding: 15px;
  width: 19.2%;
  margin: 10px 10px 10px 0;
  display: inline-block;
  border: 1px solid #fdd4e2;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  vertical-align: top;
}

.analytic-content:last-child {
  margin-right: 0;
}

.analytic-content1 {
  border: 1px solid #d8dcff;
}

.analytic-content2 {
  border: 1px solid #c6f0ff;
}

.analytic-content3 {
  border: 1px solid #ffe4cf;
}

.analytic-content4 {
  border: 1px solid #d8dcff;
}

.analytic-content h3 {
  font-size: 18px;
}

.analytic-content h4 {
  font-weight: bold;
  font-size: 25px;
  color: #484848;
  margin: 0;
  line-height: 20px;
}

.ticketbg {
  background: url(../images/ana/1.png) no-repeat right bottom
    rgba(0, 192, 239, 0.1);
  padding: 15px;
  margin: 10px 0;
}

.ticketbg h3 {
  font-size: 18px;
  color: #6fb6c8;
}

.ticketbg h4 {
  font-weight: bold;
  font-size: 25px;
  color: #000;
  margin: 0;
  margin-top: 15px;
  line-height: 20px;
}

.ticketbg1 {
  background: url(../images/ana/2.png) no-repeat right bottom
    rgba(237, 59, 130, 0.1);
}

.ticketbg1 h3 {
  color: #a07286;
}

.ticketbg2 {
  background: url(../images/ana/3.png) no-repeat right bottom
    rgba(0, 115, 183, 0.1);
}

.ticketbg2 h3 {
  color: #67818f;
}

.ticketbg3 {
  background: url(../images/ana/4.png) no-repeat right bottom
    rgba(0, 178, 157, 0.1);
}

.ticketbg3 h3 {
  color: #72ada7;
}

.ticketbg4 {
  background: url(../images/ana/5.png) no-repeat right bottom
    rgba(231, 175, 50, 0.1);
}

.ticketbg4 h3 {
  color: #a9a28b;
}

.ticketbg5 {
  background: url(../images/ana/6.png) no-repeat right bottom
    rgba(227, 61, 67, 0.1);
}

.ticketbg5 h3 {
  color: #b08c8d;
}

.poll-sna-box {
  background: rgba(0, 185, 42, 0.05);
  border: 1px solid rgba(0, 185, 42, 0.2);
  padding: 15px;
  margin: 10px 0;
}

.poll-sna-head h3 {
  color: #0195d4;
  font-size: 18px;
  font-weight: bold;
}

.poll-sna-box h4 {
  color: #000;
  font-size: 13px;
  margin: 0 0 3px 7px;
}

.poll-sna-box h4 span {
  color: #000;
  font-size: 15px;
  display: block;
  margin-top: 4px;
  font-weight: bold;
}

.poll-sna-head select {
  background-color: #fff;
  color: #7a7a7a;
  padding: 3px 24px 3px 10px;
  font-size: 13px;
  outline: none;
  border: none;
  background-position: 97% 7px;
  border: 1px solid #f2f2f2;
  height: 30px;
}

.poll-sna-box .pol-icon {
  width: 42px;
  height: 42px;
}

.poll-sna-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.policy-ana-box {
  background: rgba(255, 112, 138, 0.05);
  border: 1px solid rgba(255, 112, 138, 0.2);
}

.news-ana-box {
  background: rgba(134, 117, 255, 0.05);
  border: 1px solid rgba(134, 117, 255, 0.2);
}

.chart-boxes {
  background: #ffffff;
  border: 1px solid #ededed;
  padding: 25px;
  margin: 10px 0;
}

#rolchart .apexcharts-legend {
  display: none;
}

.anarolesdetails {
  margin-left: 30px;
}

.anarolesdetails h3 {
  color: #4a9bd4;
  font-size: 17px;
  font-weight: bold;
}

.anarolesdetails h4 {
  color: #737373;
  font-size: 15px;
  font-weight: bold;
}

.anarolesdetails p {
  color: #6e6b7b;
  font-size: 13px;
}

.scrollroles {
  max-height: 240px;
  max-width: 280px;
  overflow-y: scroll;
}

.scrollroles ul {
  padding: 0;
  list-style: none;
}

.scrollroles ul li {
  margin-bottom: 3px;
  color: #737373;
  font-size: 13px;
}

.scrollroles span {
  font-weight: bold;
  float: right;
  margin-right: 20px;
  font-size: 14px;
}

.scrollroles::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #eaeaea;
  border-radius: 10px;
}

.scrollroles::-webkit-scrollbar-thumb {
  background-color: rgba(74, 155, 212, 1) !important;
  border-radius: 10px;
}

.jvactionsbutton {
  position: absolute;
  right: 10px;
  top: -17px;
}

.jvactionsbutton button {
  padding: 5px 13px;
  font-size: 13px;
  border-radius: 15px;
}

.btn-success {
  background-color: #00bd47;
  border-color: #00bd47;
}

.surveyselectbox {
  background: url("../images/selectsurveybg.png") no-repeat;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-size: cover;
  text-align: center;
}

.surveyselectbox img {
  margin-top: 20px;
}

.surveyselectbox h4 {
  font-size: 20px;
  margin: 20px 0 10px;
  font-weight: bold;
}

.surveyselectbox p {
  font-size: 15px;
  color: #919191;
  line-height: 20px;
}

.survselecttem {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-size: cover;
  text-align: center;
}

.survselecttem h4 {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
  border: #eee thin solid;
  padding: 10px 0;
  border-left: none;
  border-right: none;
}

.survselecttem img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
}

.question-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-size: cover;
  border-left: #0195d4 5px solid;
  padding: 10px;
  margin-bottom: 20px;
}

.question-box h4 {
  font-size: 18px;
  margin: 0 0 15px;
  font-weight: bold;
  border-bottom: #eee thin solid;
  padding: 0 0 7px 0;
}

.add_newquest {
  border: none;
  outline: none;
  border-bottom: #c5c5c5 thin solid;
  margin-left: 35px;
  width: 200px;
}

.privatepublic label {
  line-height: 20px;
  padding-left: 0;
  border: #e3e3e3 thin dashed;
  padding: 10px 50px 10px 10px;
}

.privatepublic span {
  color: #a5a5a5;
}

.privatepublic .checkmark {
  left: auto;
  top: 20px;
  right: 10px;
}

.permissionpublic label {
  padding: 10px 10px 10px 35px;
  margin-bottom: 10px;
}

.permissionpublic .checkmark {
  left: 8px;
  top: 13px;
}

.ideabox {
  background: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-left: #a074b3 5px solid;
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
}

.ideabox:nth-child(3n + 2) {
  border-color: #f5af36;
}

.ideabox:nth-child(3n + 3) {
  border-color: #1eb7c3;
}

.ideabox h4 {
  font-size: 16px;
  font-weight: 300;
  color: #666666;
  display: flex;
  align-items: center;
}

.ideabox h4 i {
  font-size: 4px;
  color: #a074b3;
  margin: 2px 10px 0;
}

.ideabox h4 span {
  background: rgba(160, 116, 179, 0.15);
  font-size: 13px;
  padding: 2px 10px;
  border-radius: 3px;
  color: #a074b3;
  font-weight: bold;
}

.ideabox h5 {
  font-size: 18px;
  color: #000;
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.ideabox h6 {
  font-size: 17px;
  font-weight: 300;
  color: #696a6a;
  margin-top: 6px;
}

.ideabox .btn-blanktd {
  outline: none;
  padding: 0;
  color: #a074b3;
  font-weight: bold;
  font-size: 16px;
  margin-top: 6px;
}

.ideabox .btn-blanktd i {
  font-size: 12px;
}

.ideabox .btn-warning {
  background: #f1eaf4;
  border: 1px solid #a074b3;
  border-radius: 3px;
  color: #a074b3;
  font-weight: bold;
  font-size: 16px;
  padding: 2px 10px;
  margin-left: 10px;
}

.ideabox:nth-child(3n + 2) .btn-warning {
  background: #fdf3e1;
  border: 1px solid #f5af36;
  color: #f5af36;
}

.ideabox:nth-child(3n + 3) .btn-warning {
  background: #ddf4f6;
  border: 1px solid #1eb7c3;
  color: #1eb7c3;
}

.ideabox .btn-success {
  background: #e0ffe9;
  border: 1px solid #109d38;
  color: #109d38;
}

.ideabox:nth-child(3n + 2) h4 span {
  background: rgba(245, 175, 54, 0.15);
  color: #f5af36;
}

.ideabox:nth-child(3n + 2) h4 i,
.ideabox:nth-child(3n + 2) .btn-blanktd {
  color: #f5af36;
}

.ideabox:nth-child(3n + 3) h4 span {
  background: rgba(30, 183, 195, 0.15);
  color: #1eb7c3;
}

.ideabox:nth-child(3n + 3) h4 i,
.ideabox:nth-child(3n + 3) .btn-blanktd {
  color: #1eb7c3;
}

.idealistpagination ul {
  margin-right: 0;
}

.ideaboxleft {
  padding: 20px;
  background: #fff;
  border-radius: 5px 0 0 5px;
  margin: 15px 0;
}

.totalidea h4 {
  color: #4a9bd4;
  font-size: 40px;
}

.totalidea p {
  color: #666666;
  margin: 20px 0 0;
  font-size: 17px;
}

.inputideabox .team-profile {
  width: 40px;
  height: 40px;
}

.ideabox .btn-blanktd.editidea {
  margin: 0;
  width: 30px;
  padding: 0;
  border-radius: 50%;
  height: 30px;
  background: rgba(160, 116, 179, 0.35);
}

.ideabox .btn-blanktd.editidea i {
  font-size: 14px;
}

.ideabox:nth-child(3n + 2) .btn-blanktd.editidea {
  background: rgba(245, 175, 54, 0.35);
}

.ideabox:nth-child(3n + 3) .btn-blanktd.editidea {
  background: rgba(30, 183, 195, 0.35);
}

.ideabox h4 i.ideacomments {
  background: #f1eaf4;
  color: #a074b3;
  font-size: 14px;
  border-radius: 5px;
  padding: 3px 5px;
  margin: 0 3px;
}

.ideabox:nth-child(3n + 2) h4 i.ideacomments {
  background: #fdf3e1;
  color: #f5af36;
}

.ideabox:nth-child(3n + 3) h4 i.ideacomments {
  background: #ddf4f6;
  color: #1eb7c3;
}

.attachemntbox {
  color: #000 !important;
  font-size: 17px !important;
  margin: 15px 0px 5px !important;
}

.ideastatusbar {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
}

.ideastatusbar li {
  display: inline-block;
  width: 25%;
  position: relative;
  vertical-align: top;
}

.ideastatusbar .ideadefstat {
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 50%;
  background: #fff;
  border: #a1a1a1 2px solid;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
}

.ideastatusbar span {
  width: 17px;
  height: 17px;
  display: block;
  border-radius: 50%;
  background: #a1a1a1;
  text-align: center;
  line-height: 17px;
  font-weight: bold;
  color: #fff;
  font-size: 15px;
}

.ideastatusbar li:after {
  content: "";
  position: absolute;
  top: 12px;
  height: 4px;
  border-top: #afafaf 2px dashed;
  width: 95%;
  right: 100%;
  z-index: 1;
}

.ideastatusbar li:first-child:after {
  display: none;
}

.ideastatusbar .completed .ideadefstat {
  border: #34a853 2px solid;
}

.ideastatusbar .completed .ideadefstat span {
  background: #34a853;
}

.ideastatusbar .completed:after {
  border-color: #34a853;
}

.ideastatusbar .rejected .ideadefstat {
  border: #f44336 2px solid;
}

.ideastatusbar .rejected .ideadefstat span {
  background: #f44336;
}

.ideastatusbar .rejected:after {
  border-color: #f44336;
}

.ideastatusbar h4 {
  color: #000;
  font-weight: 400;
  font-size: 15px;
}

.ideastatusbar p {
  color: #a1a1a1;
  font-weight: 300;
  font-size: 13px;
}

.leavetableapply .form-control {
  color: #9f9f9f;
  font-weight: 400;
}

.leavetableapply .form-control:disabled {
  background: #eee !important;
}

.leave-cc-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-top: -40px;
  margin-bottom: 15px;
  border: #fff 3px solid;
  box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.leave-cc-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.dashbed-border-bottom {
  border-bottom: 1px dashed #dee2e6 !important;
}

.dashTitle {
  font-size: 45px;
  margin-bottom: 0;
  line-height: 50px;
  margin-top: 5px;
}

.leave-apply-section {
  padding: 15px;
  border-radius: 10px;
  border: none;
  background: #fbfbfb;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
}

.leave-apply-section h5 {
  font-size: 19px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #0195d4;
  margin-bottom: 5px;
}

.leave-apply-section h6 {
  color: #6e6d6d;
  font-size: 12px;
  margin-top: 0;
}

.dot-blue {
  font-size: 13px;
}

.leave-apply-section h5.text-dark {
  font-size: 15px;
}

.leave-grid-status {
  position: absolute;
  top: -27px;
  right: 0;
}

.leave-grid-status .bad-status {
  border-radius: 25px;
  padding: 3px 12px;
  font-weight: 700;
  font-size: 11px;
}

.leave-grid-status .bad-status.badge-danger {
  background: #ffd9d9;
  color: #f25252 !important;
}

.leave-grid-status .bad-status.badge-success {
  background: #deffdf;
  color: #089d12 !important;
}

.leave-grid-status .bad-status.badge-warning {
  background: #ffe7b9;
  color: #e69700 !important;
}

.leavestatustab th {
  background: none;
  padding: 0px 5px;
  font-size: 13px;
  border: none;
}

.leavestatustab td {
  background: none;
  padding: 3px 5px;
  font-size: 13px;
  color: #838383;
}

.leavestatustab td:nth-child(2) {
  color: #000;
}

.leavestatustab td:last-child span {
  font-weight: bold;
  background: rgba(115, 103, 240, 0.12);
  color: #7367f0;
  padding: 2px 5px;
  font-size: 11px;
  border-radius: 5px;
}

.leavestatustab tbody + tbody {
  border: none;
}

.ck.ck-editor__editable_inline p {
  color: #000;
  font-size: 17px;
}

.add-imagequestion {
  padding: 0;
  color: #0195d4;
  background: none;
  height: auto;
  font-size: 14px;
}

.page-headapprover {
  margin: 18px 0 0;
  border: none;
  text-align: left;
  padding-left: 20px;
  color: #666666;
  background: #fff;
  border-bottom: #eee thin solid;
  padding: 12px 14px 6px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.page-headapprover i {
  font-size: 18px;
  margin-right: 5px;
}

.surveyviewquestions h3 {
  color: #000;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}

.surveyviewquestions p {
  color: #475467;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.question-boxpollsurvey {
  margin-top: 35px;
}

.question-boxpollsurvey h4 {
  color: #4a9bd4;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 3px;
}

.question-boxpollsurvey h5 {
  color: #4e4e4e;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 22px;
}

.answerboxpollsuy {
  background: rgba(182, 182, 182, 0.2);
  border-radius: 6px;
  padding: 10px;
  margin-top: 14px;
}

.answerboxpollsuy span {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.answerboxpollsuy .logCheck {
  padding-left: 29px;
  color: #707070;
  font-weight: 400;
  font-size: 15px;
}

.answerboxpollsuy .logCheck input[type="radio"] + .checkmark {
  border-radius: 50%;
}

.answerboxpollsuy .logCheck input[type="radio"] + .checkmark:after {
  border-radius: 50%;
  width: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-width: 3px;
}

.answerboxpollsuy .form-control {
  background: none;
  border: none !important;
  border-radius: 0;
  padding: 0;
  height: auto;
}

.answerboxpollsuy img,
.answerboxpollsuy video {
  max-width: 200px;
  width: 100%;
}

.centerradioanswer .checkmark {
  top: 45%;
}

.ratingpoll .logCheck {
  padding-left: 28px;
}

.ratingpoll .checkmark {
  background: none !important;
  border: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  color: #000;
  font-size: 17px;
  color: #4e4e4e;
}

.ratingpoll .checkmark:before {
  content: "\f005";
}

.ratingpoll .logCheck input:checked + .checkmark {
  font-weight: 900;
  color: #4a9bd4;
}

.ratingpoll .logCheck input:checked + .checkmark:after {
  display: none;
}

.ratingpoll span {
  font-size: 14px;
}

.accordion-button {
  border: none;
  border-bottom: #eee 2px solid;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 19px;
  font-weight: bold;
  padding: 14px 25px 16px 0;
  background: url(../images/acclose.png) no-repeat right #fff;
}

.accordion-button.collapsed {
  background: url(../images/acplus.png) no-repeat right #fff;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-body {
  border-bottom: #eee 2px solid;
  padding: 10px 20px 20px;
  color: rgba(60, 60, 67, 0.85);
  font-size: 16px;
  line-height: 22px;
}

.help-deskbg {
  background: rgba(74, 155, 212, 0.05);
  border: 1px solid rgba(74, 155, 212, 0.4);
  border-radius: 5px;
  padding: 40px;
  color: #000;
  text-align: center;
  margin-top: 20px;
}

.help-deskbg h2 {
  font-size: 28px;
  font-weight: normal;
  margin: 35px 0 12px;
}

.help-deskbg h3 {
  font-size: 15px;
  margin-bottom: 12px;
}

.help-deskbg h4 {
  font-size: 35px;
  margin: 0;
}

.ticketviewbox {
  background: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 16px;
  padding: 20px;
  height: 100%;
}

.ticketviewbox h3 {
  font-size: 21px;
  font-weight: bold;
}

.ticketviewbox h4 {
  font-size: 18px;
  margin-bottom: 0;
  color: #667085;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ticketviewbox h4 span {
  font-weight: bold;
  color: #4a9bd4;
  margin-left: 10px;
}

.ticketviewbox h4 i {
  color: #c4c4c4;
  margin-right: 3px;
}

.ticketviewbox ul {
  padding-left: 25px;
}

.ticketviewbox li {
  color: #667085;
}

.ticketviewbox li span {
  color: #4a9bd4;
  font-weight: bold;
}

.ticketviewbox h5 {
  font-size: 16px;
  font-weight: bold;
  color: #667085;
  margin-bottom: 13px;
}

.ticketviewbox h6 {
  font-size: 17px;
  font-weight: 400;
  color: #667085;
  margin-bottom: 13px;
}

.ticketviewbox p {
  font-size: 17px;
  color: #000;
  margin-bottom: 10px;
}

.ticketviewbox p:last-child {
  margin-bottom: 0px;
}

.viewraisehead {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.agent {
  margin-bottom: 20px;
}

.agent:last-child {
  margin-bottom: 0px;
}

.agent h4 {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.agent h6 {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.ticketreplytxt {
  background: #f4f4f4;
  font-size: 14px;
  padding: 10px;
  color: #555555;
}

.reply h4,
.reply h6 {
  color: #4a9bd4;
}

.raise-scroll {
  max-height: 350px;
}

.raisereplybox {
  position: relative;
}

.raisereplybox textarea {
  height: 80px;
  padding-right: 101px;
}

.raisereplybox button {
  position: absolute;
  right: 24px;
  background: #4a9bd4;
  color: #fff;
  text-align: center;
  width: 63px;
  height: 63px;
  line-height: 63px;
  padding: 0;
  font-size: 25px;
  top: 24px;
}

.raisereplybox .btn-sound {
  right: 100px;
  background: none;
  padding: 0;
  color: #545454;
  height: auto;
  line-height: normal;
  font-size: 20px;
  width: auto;
}

.timeline-box h4 {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.timeline-box h5 {
  font-size: 15px;
  color: #667085;
  margin-bottom: 13px;
}

.timeline-box h6 {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
  margin-bottom: 40px;
}

.timeline-box p {
  font-size: 15px;
  color: #667085;
  margin-bottom: 0px;
}

.admin-guide-data {
  padding-top: 20px;
  padding-bottom: 20px;
}

.admin-guide-data h3 {
  font-size: 24px;
  font-weight: bold;
}

.admin-guide-data p {
  color: #555555;
  line-height: 20px;
}

.module-head {
  background: #e9f6ff;
}

.custom-policy-txt h5 {
  margin-top: 30px;
  color: #0195d4;
  text-transform: uppercase;
  font-size: 18px;
}

.custom-policy-txt h5:first-child {
  margin-top: 0px;
}

.custom-policy-txt ul li {
  color: #555555;
}

.custom-policy-txt h6 {
  color: #000;
  line-height: 20px;
  font-size: 16px;
}

.adminguidelist {
  margin-top: 30px;
}

.adminguidelist .accordion-button {
  background: none;
  border: none;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 13px 0;
}

.adminguidelist .accordion-button i {
  font-size: 8px;
  margin-right: 10px;
}

.adminguidelist .accordion-button i.fa-chevron-down {
  font-size: 13px;
  margin-right: 0;
  margin-left: 10px;
  transform: rotate(180deg);
}

.adminguidelist .accordion-button.collapsed i.fa-chevron-down {
  transform: rotate(0deg);
}

.adminguidelist .accordion-button.collapsed {
  color: #595959;
  font-weight: normal;
}

.adminguidelist .accordion-body {
  padding: 0;
  border: none;
}

.adminguidelist .accordion-body ul {
  padding: 0;
  list-style: none;
  padding-left: 20px;
  margin: 7px 0 15px 0;
  position: relative;
}

.adminguidelist .accordion-body ul li {
  margin-bottom: 10px;
}

.adminguidelist .accordion-body ul li,
.adminguidelist .accordion-body ul li a {
  color: #818181;
  font-size: 16px;
}

.adminguidelist .accordion-body ul li:hover,
.adminguidelist .accordion-body ul li:hover a {
  color: #4a9bd4;
}

.adminguidelist .accordion-body ul li.active,
.adminguidelist .accordion-body ul li.active a {
  color: #4a9bd4;
  font-weight: bold;
}

.adminguidelist .accordion-body ul li.active:before {
  content: "";
  position: absolute;
  left: -15px;
  border-radius: 30px;
  width: 4px;
  height: 22px;
  background: #4a9bd4;
}

.profile-cover {
  border-radius: 5px;
  height: 190px;
  width: 100%;
  position: relative;
}

.profile-cover img {
  border-radius: 5px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.upload-imagefile {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.upload-imagefile .uploadBtn {
  background: rgba(230, 244, 251, 0.6);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0;
  color: #4a9bd4;
}

.profile-detail .profile-cover {
  margin-top: -60px;
}

.profile-detail h2 {
  font-size: 27px;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #0194d2;
  margin-top: 40px;
}

.profile-detail h3 {
  font-size: 18px;
  color: #6e6b7b;
}

.profile-detail h3 span {
  background: rgba(1, 148, 210, 0.1);
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #0194d2;
  padding: 1px 8px;
  margin-left: 20px;
}

.profiletab {
  margin: 0;
}

.profiletab .nav-tabs .nav-link {
  font-weight: 400;
  padding-right: 0;
}

.profiletab .nav-tabs .nav-link.active {
  font-weight: bold;
}

.profiletab .nav-tabs {
  border: none;
  justify-content: center;
}

.profiletab .nav-tabs .nav-item {
  margin-right: 50px;
}

.profiletab .tab-content {
  border-top: #f8f8f8 20px solid;
  margin: 0px -0.5rem 0 -0.5rem;
  padding: 40px 80px 20px;
}

.profilesecdetail h4 {
  font-size: 16px;
  font-weight: bold;
  color: #6e6b7b;
  margin-bottom: 27px;
}

.profilesecdetail h5 {
  font-size: 16px;
  color: #6e6b7b;
  text-align: right;
}

.profilesecdetail h5.text-theme {
  color: #0195d4;
}

.backleftlist {
  margin-top: -15px;
}

.backleftlist span {
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  background: #eee;
  color: #000;
  border-radius: 20px;
}

.secondthhdata th {
  background: #26b3ef !important;
}

.travel-note h4 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
}

.travel-note ul {
  padding-left: 15px;
  margin: 0;
}

.travel-note ul li {
  padding: 0;
  font-size: 14px;
  list-style: deciaml;
}

.innergroup label.acceptchkbox {
  font-size: 15px;
  font-weight: normal;
  color: #666666;
}

.tickethistorbg {
  background: #f1faff;
  border: 1px dashed #009ef7;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 15px;
}

.tickethistorbg1 {
  background: #fff;
  border: 1px solid #009ef7;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 15px;
}

.tickethistorbg p {
  border: none;
  margin: 0;
  padding: 0;
}

.vehiclelog .form-control {
  min-width: 85px;
}

.travelintim .btn-primary-inner:disabled {
  background: #b5b5b5;
  border-color: #b5b5b5;
}

.table-advance th {
  color: #000;
  font-size: 18px;
  padding: 8px 10px;
  border: none !important;
}

.table-advance td {
  color: #6e6b7b;
  font-size: 18px;
  padding: 10px;
}
.tablevehicle table td {
  padding: 5px 5px;
}

.tablevehicle {
  position: relative;
  height: 340px;
  overflow: auto;
}
.tablevehicle table thead {
  position: sticky;
  top: 0px;
}
.tablevehicle table thead th {
  position: sticky;
  top: 0;
}

.table-advance td:last-child {
  color: #4a9bd4;
  font-weight: bold;
}

.backviewpo {
  margin: 0 px;
  text-align: right;
}

.addmoretravleblank .btnblanktd {
  padding-left: 0;
  padding-right: 0;
}

.npsinfosec td {
  font-size: 15px;
  padding: 5px;
}

.btn-digital {
  border: 1px solid #0095d4;
  background: #0095d4;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  padding: 3px 10px;
}

.emp-digitalbox .modal-content {
  border: none;
}

.emp-digitalbox .modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
}

.emp-digitalbox .modal-header {
  border: none;
  padding: 25px 28px 0;
}

.emp-digitalbox .modal-body {
  padding: 10px 28px 25px;
}

.emp-digitalbox .btn-share {
  border: 1px solid #6e6d6d;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-left: auto;
  outline: none;
  background: none;
  border-radius: 50%;
}

.emp-digitalbox .btn-share img {
  width: 23px;
}

.emp-digitalbox .btn-close {
  position: relative;
  top: 0;
  right: 0;
  background-color: #4a9bd4;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: url("../images/digi-close.png") #4a9bd4 no-repeat center;
}

.emp-digitalbox .modal-title {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
  color: #4a9bd4;
  border-bottom: none;
  line-height: normal;
  padding-bottom: 4px;
  padding-right: 25px;
}

.emp-digicontent h2 {
  font-size: 14px;
  font-weight: 700;
  color: #5e5e5e;
  margin: 0 0 15px;
  display: flex;
  align-items: flex-start;
}

.emp-digicontent h3 {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin: 0 0 15px;
  display: flex;
  align-items: flex-start;
}

.emp-digicontent h4 {
  font-size: 16px;
  font-weight: 400;
  color: #4a9bd4;
  margin: 0 0 15px;
  display: flex;
  align-items: flex-start;
}

.emp-digicontent h5 {
  font-size: 14px;
  font-weight: 700;
  color: #5e5e5e;
  margin: 0 0 15px;
  display: flex;
  align-items: flex-start;
}

.emp-digicontent h6 {
  font-size: 22px;
  font-weight: bold;
  color: #6e6d6d;
  margin: 0;
}

.emp-digicontent .icondesign img {
  width: 15px;
}

.emp-digicontent .icondesign {
  margin-right: 5px;
}

.emp-digicontent .border {
  border-color: #000 !important;
  border-radius: 10px;
  border-width: 2px !important;
}

.emp-digicontent h5 .icondesign img {
  width: 31px;
}

.emp-digicontent h5 .icondesign {
  margin-right: 3px;
}

.preloader {
}

.loadingBox {
  display: flex;
  align-items: center;
}

.preloader p {
  font-size: 13px;
  color: #919191;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 5px;
}

.loadingbar span {
  transition: all 500ms ease;
  background: #4a72da;
  height: 3px;
  width: 3px;
  display: inline-block;
  border-radius: 10px;
  margin: 0 2px;
  animation: wave 2s ease infinite;
}

.loadingbar span:nth-child(1) {
  animation-delay: 0;
}

.loadingbar span:nth-child(2) {
  animation-delay: 100ms;
}

.loadingbar span:nth-child(3) {
  animation-delay: 200ms;
}

@keyframes wave {
  0%,
  40%,
  100% {
    transform: translate(0, 0);
    background-color: #919191;
  }

  10% {
    transform: translate(0, -7px);
    background-color: #34343d;
  }
}

.defaultchatgptquest .badge {
  background: #eee;
  border: none;
  outline: none;
  color: #7a7a7a;
  padding: 7px 13px;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.defaultchatgptquest .badge-primary {
  background: #0195d4;
  border: none;
  outline: none;
  color: #fff;
  padding: 7px 13px;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.chtgptbox .agent {
  margin-bottom: 10px;
}

.chtgptbox .ticketreplytxt {
  padding: 5px 10px;
  display: inline-block;
  background: #ebebeb;
}

.chtgptbox .ticketreplytxt.bg-white {
  background: #f7f7f7 !important;
}

.listenpopup .modal-dialog {
  max-width: 100%;
  margin: 0;
  height: 100%;
}

.listenpopup .modal-content {
  border: none;
  border-radius: 0;
  height: 100%;
}

.listenpopup .modal-header {
  border: none;
}

.listenpopup .preloader p {
  font-size: 50px;
  font-weight: 400;
  line-height: 46px;
}

.listenpopup .loadingBox {
  align-items: end;
  justify-content: center;
}

.listenpopup .loadingbar span {
  width: 5px;
  height: 5px;
  margin-right: 5px;
}

.bs-tooltip-end .tooltip-inner {
  position: relative;
  background: #fff;
  border: 1px solid #e3e3e3;
  margin-left: 10px;
  color: #000;
  font-size: 12px;
}

.bs-tooltip-end .tooltip-inner:after,
.bs-tooltip-end .tooltip-inner:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.bs-tooltip-end .tooltip-inner:after {
  border-color: rgba(227, 227, 227, 0);
  border-right-color: #ffffff;
  border-width: 5px;
  margin-top: -5px;
}

.bs-tooltip-end .tooltip-inner:before {
  border-color: rgba(238, 238, 238, 0);
  border-right-color: #e3e3e3;
  border-width: 6px;
  margin-top: -6px;
}

.bs-tooltip-bottom .tooltip-inner {
  position: relative;
  background: #202020;
  border: 1px solid #e3e3e3;
  color: #fff;
  font-size: 12px;
}

.bs-tooltip-bottom .tooltip-inner:after,
.bs-tooltip-bottom .tooltip-inner:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  transform: rotate(90deg);
}

.bs-tooltip-bottom .tooltip-inner:after {
  border-color: rgba(227, 227, 227, 0);
  border-right-color: #202020;
  border-width: 5px;
  margin-left: -5px;
}

.bs-tooltip-bottom .tooltip-inner:before {
  border-color: rgba(238, 238, 238, 0);
  border-right-color: #202020;
  border-width: 6px;
  margin-left: -6px;
}

.infotabseacrh {
  margin: 13px 3px 0 0;
  font-size: 14px;
}

.bspoc-box {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 15px;
  height: 100%;
}

.bspoc-box h2 {
  font-size: 17px;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;
}

.bspoc-box h3 {
  font-size: 14px;
  color: #000;
  margin: 0;
}

.react-confirm-alert .react-confirm-alert-button-group button {
  background: #0195d4;
  border-color: #0195d4;
  color: #fff;
}

.sharbox {
  background: #f6f6f6;
  padding: 5px 10px;
  margin-top: 16px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sharbox p {
  color: #65666a;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 0 20px 3px 0;
  margin-bottom: 0px;
}

.sharbox p i {
  margin-right: 5px;
  font-size: 14px;
}

.sharbox button + button {
  margin-left: 10px !important;
}

.sharbox button svg {
  width: 21px;
}

.report-issue {
  position: fixed;
  right: 15px;
  bottom: 60px;
  z-index: 10;
  cursor: pointer;
  text-align: center;
}

.raise-text{
  font-weight: bold;
  font-size: 11px;
}

.reportanissuepoup .modal-content {
  background: linear-gradient(180deg, #fefff1 0%, #ebfbff 100%);
  border: none;
}

.reportanissuepoup .modal-content .modal-header {
  background: none;
  border: none;
  display: block;
  padding-bottom: 0;
  margin-bottom: -8px;
}

.reportanissuepoup .modal-title {
  font-size: 22px;
}

.reportanissuepoup h5 {
  font-size: 18px;
  color: #676767;
  font-weight: 300;
  margin-top: 2px;
}

.reportanissuepoup .form-control {
  border: 2px solid #4a9bd4;
  border-radius: 6px;
}

.reportanissuepoup .btn-primary {
  background: #4a9bd4;
  border-radius: 30px;
  position: relative;
  padding: 5px 27px 6px 31px;
  border: none;
  text-align: center;
  line-height: 37px;
  float: right;
  margin: -20px 20px 5px 0;
}

.reportanissuepoup .btn-primary:hover {
  background: #2a8bcf;
}

.reportanissuepoup .btn-close {
  top: 20px;
  right: 19px;
  border: none;
  box-shadow: none;
  background-color: rgba(74, 155, 212, 0.4);
}

.reportanissuepoup.fade .modal-dialog {
  margin: 0 0 0 auto;
  height: 100%;
  align-items: end;
  display: flex;
  transform: translateY(50px);
}

.reportanissuepoup.show .modal-dialog {
  transform: none;
}

.reportanissuepoup {
  overflow: hidden !important;
}

.myteam-table {
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-bottom: 0;
}

.myteam-table th {
  padding: 0 0 3px 5px;
  border: none;
  color: #4a9bd4;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
  white-space: nowrap;
}

.myteam-table td {
  padding: 7px 0 7px 5px;
  border: none;
  vertical-align: middle;
  background: #f3f3f3;
}

.myteam-table td:last-child {
  border-radius: 0 5px 5px 0;
}

.myteam-table td:first-child {
  border-radius: 5px 0 0 5px;
  padding-left: 20px;
}

.myteam-table td .teamtxt h5 {
  width: 210px;
  max-width: 210px;
  margin-bottom: 2px;
}

.myteam-table td .teamtxt p {
  font-size: 13px;
  color: #6e6b7b;
  letter-spacing: 2px;
}

.myteam-table td .teamtxt p span {
  color: #4a9bd4;
  letter-spacing: 0;
}

.myteam-table td .teamtime {
  font-size: 12px;
  font-weight: 600;
}

.morebtn {
  font-size: 16px;
  line-height: 28px;
  padding: 3px 20px 0 0;
}

.morebtn a {
  display: inline-flex;
  align-items: center;
  justify-content: end;
}

.morebtn a i {
  margin-left: 10px;
}

.leave-indicator {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  color: #6e6b7b;
  margin: 10px 0 0;
}

.leave-indicator span {
  width: 8px;
  height: 8px;
  margin-right: 2px;
  border-radius: 50%;
  background: #7367f0;
  display: inline-block;
}

.leave-indicator .chkpresent span {
  background: #35bd59;
}

.leave-indicator .chkabsent span {
  background: #0096ff;
}

.leave-indicator .chkholiday span {
  background: #666666;
}

.leave-indicator .chkpucnhout span {
  background: #f8e822;
}

.ginniechatgpt {
  background: url(../images/ginnebg.png) #fff no-repeat top right;
}

.ginniechatgpt .answerboxpollsuy {
  background: none !important;
}

.ginniechatgpt .answerboxpollsuy .logCheck {
  border-radius: 3px;
  padding: 10px;
  min-width: 200px;
  text-align: center;
  font-size: 23px;
  font-weight: normal !important;
  color: #acacac;
  margin-top: 30px;
}

.ginniechatgpt .answerboxpollsuy .logCheck strong {
  font-weight: 400 !important;
  color: #acacac;
}

.ginniechatgpt .answerboxpollsuy .logCheck input[type="radio"] ~ .checkmark {
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  width: 100%;
  height: 100%;
  background: none;
}

.ginniechatgpt .answerboxpollsuy .logCheck input:checked ~ .checkmark {
  background: rgba(74, 155, 212, 0.15);
  border: 1px solid #4a9bd4;
}

.ginniechatgpt .answerboxpollsuy .logCheck input:checked ~ div strong {
  color: #4a9bd4;
}

.ginniechatgpt .logCheck input:checked ~ .checkmark::after {
  visibility: hidden;
}

.ginniechatgpt .chtgptbox {
  background: url(../images/chantbg.png) #fff no-repeat;
  background-size: cover;
  border: 1px solid #4a9bd46a !important;
  border-radius: 5px !important;
}

.ginniechatgpt .agent h6 {
  font-weight: 700;
  color: #4a9bd4;
}

.ginniechatgpt .chtgptbox .ticketreplytxt.bg-white {
  background: #4a9bd4 !important;
  color: #fff;
}

.ginniechatgpt .chtgptbox .ticketreplytxt {
  position: relative;
  border-top-left-radius: 0 !important;
}

.ginniechatgpt .chtgptbox .ticketreplytxt:after {
  right: 100%;
  top: 0;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #ebebeb;
  border-width: 0px 11px 10px 5px;
  margin-top: 0px;
}

.ginniechatgpt .chtgptbox .ticketreplytxt.bg-white {
  position: relative;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0.25rem !important;
}

.ginniechatgpt .chtgptbox .ticketreplytxt.bg-white:after {
  left: 100%;
  top: 0;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #4a9bd4;
  border-width: 0px 11px 10px 10px;
  margin-top: 0px;
}

.ginniechatgpt .raisereplybox {
  background: rgba(74, 155, 212, 0.1);
  border: 0.4px solid #4a9bd4;
  border-radius: 50px;
}

.ginniechatgpt .raisereplybox textarea {
  border: none;
  background: none;
  height: 50px;
  border-radius: 50px;
  padding-left: 60px;
  line-height: 34px;
  font-size: 18px;
}

.ginniechatgpt .raisereplybox .btn-sound {
  left: 5px;
  top: 4px;
  border-radius: 50px;
  background: #4a9bd4;
  text-align: center;
  width: 41px;
  height: 41px;
  z-index: 1;
}

.ginniechatgpt .raisereplybox .btn-blank {
  border-radius: 60px;
  width: 71px;
  height: 41px;
  top: 4px;
  right: 5px;
  font-size: 20px;
  line-height: 41px;
}

.checkexpentable .form-control {
  padding: 0 17px 0 5px;
  font-size: 12px;
  border-radius: 4px;
  height: auto;
}

.checkexpentable input.form-control {
  padding: 0 0px 0 5px;
  font-size: 12px;
  border-radius: 4px;
  height: auto;
}

.data-certibg {
  background: linear-gradient(
    180deg,
    rgba(74, 155, 212, 0.2) 0%,
    rgba(248, 232, 34, 0.2) 100%
  );
}

.data-certibg .mand-page-head {
  font-size: 35px;
}

.data-correctionbox {
  border-radius: 120px;
  background: #c6dee7;
  padding: 12px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.data-coreectafterwh {
  position: relative;
  cursor: pointer;
  margin-bottom: 35px;
}

.data-coreectafterwh:after {
  content: "";
  position: absolute;
  left: -8px;
  top: -8px;
  width: 95px;
  height: 95px;
  background: #fff;
  border-radius: 50%;
  z-index: 1;
}

.data-correctionbox .icondata {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  font-size: 23px;
  background: #fff;
  text-align: center;
  line-height: 55px;
}

.data-correctionbox h2 {
  font-size: 19px;
  font-weight: 700;
  margin-left: 15px;
  color: #4a9bd4;
  width: 100%;
  margin-bottom: 0;
}

.data-correctionbox h2 i {
  float: right;
  margin-right: 15px;
}

.data-certipopupinfo {
  padding: 0;
}

.data-certipopupinfo li {
  font-size: 17px;
  font-weight: 400;
  color: #000;
  line-height: 25px;
  list-style: none;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
  counter-increment: custom;
}

.data-certipopupinfo li::before {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 5px;
  left: -21px;
  background: #0195d4;
  border-radius: 50%;
  content: counter(custom) " ";
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.data-certipopupinfo li::marker {
  color: #fff;
  font-size: 13px;
}

.data-cert-rightwrng {
  background: rgba(241, 136, 136, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  padding: 7px;
  font-size: 13px;
  font-weight: 700;
  color: #f00;
  line-height: 20px;
}

.data-cert-rightwrng i {
  margin-right: 5px;
  margin-top: 3px;
}

.data-certiprogreess {
  display: flex;
  align-items: center;
  color: #4a9bd4;
  font-size: 18px;
  font-weight: 700;
}

.data-certiprogreess .progress {
  width: 85%;
  float: left;
  margin-right: 15px;
  border-radius: 30px;
  border: 1px solid #4a9bd4;
  background: #fff;
}

.data-certiprogreess .progress-bar {
  background: #4a9bd4;
}

@media Print {
  .new-table td {
    color: #000000 !important;
  }
}

@media print {
  #table-responsive{
   width: 100%;
   height: 100%;
   overflow-x: visible !important; 
  }
  #viewpo{
  display: block;
  width: auto;
  height: auto;
  overflow-x: visible; 
 }
}