.agri-portal-table input{
  height: 20px;
  font-size: 13px !important;
}

.agri-portal-table select{
  padding: 0 0 0 5px !important;
  height: 20px !important;
  font-size: 13px !important;
  -webkit-appearance: checkbox !important;
  
}


/* Chrome, Safari, Edge, Opera */
.agri-portal-table ::-webkit-outer-spin-button,
.agri-portal-table ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.agri-portal-table input[type=number] {
  -moz-appearance: textfield;
}

.agri-portal-table{
    padding: 5px !important;
}

.agri-portal-table {
    height: 20px;
    font-size: 13px !important;
}

.agri-portal-table select{
    padding: 0 0 0 5px !important;
    height: 20px !important;
    font-size: 13px !important;
    -webkit-appearance: checkbox !important;
    
}