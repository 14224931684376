
  /*----Checkin cal-----*/

  .checkinCalboxx {
    padding: 13px;
   
  
  }
  .checkinCalboxx .fc .fc-toolbar.fc-header-toolbar {
    border: none;
    padding: 0 10px 7px;
    align-items: end;
  }
  .checkinCalboxx .fc-media-screen {
    margin: 0;
  }
  .checkinCalboxx .fc .fc-button-primary {
    background: blue;
    padding: 0 0 0 7px;
    border: none;
    margin-top: -3px;
    font-size: 15px;
    font-weight: bold;
  }
  .checkinCalboxx .fc .fc-button-primary:not(:disabled):active {
    background: none;
    border: none;
  }
  .checkinCalboxx .fc .fc-button-primary span:hover {
    color: #4a9bd4;
  }
  .checkinCalboxx .fc .fc-col-header-cell-cushion {
    color: #4a9bd4;
    text-transform: uppercase;
    padding-bottom: 10px;
  }
  
  .checkinCalboxx
    .fc
    .fc-daygrid-day.fc-day-today
    .fc-daygrid-day-frame
    .fc-daygrid-day-top {
    width: auto;
    height: auto;
    background: none !important;
    
  }

  .checkinCalboxx
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid !important;
  }

  .fc .fc-daygrid-day-frame {
    
    margin: 0px;
  }
  .checkinCalboxx
    .fc
    .fc-daygrid-day.fc-day-today
    .fc-daygrid-day-frame
    .fc-daygrid-day-number {
    color: #4a9bd4;
  }
  
  .checkinCalboxx .fc .fc-daygrid-day-number {
    line-height: normal;
    font-size: 15px;
    color: #000;
    padding: 0;

  }
  
  .checkinCalboxx .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 0.5rem;
    display: block;
    cursor: pointer;
    
  }

  .checkinCalboxx
    .fc
    .fc-daygrid-day.fc-day-today
    .fc-daygrid-day-frame
    .fc-h-event
    .fc-event-main {
    color: #000 !important;
  }
  .checkinCalboxx .fc .fc-daygrid-day.fc-day-today {
    cursor: pointer;
  }
  
  /* .checkinCalboxx .dotcheckincircle {
    width: 6px;
    height: 6px;
    background: #666666;
    border-radius: 50%;
    margin: 2px auto 8px !important;
    border: none;
  } */
    .checkinCalboxx .dotcheckincircle{
  margin-left: 10px;
  border: none;
  padding-right: 20px;
  background-color: white;

  
  } 

  .fc-scroller fc-scroller-liquid-absolute{
    
  }
  .checkinCalboxx .dotcheckincircle.green {
    background: #35bd59;
    pointer-events: none;
    cursor: pointer;
  } 
  
  .checkinCalboxx .dotcheckincircle.red {
    background: red;
    pointer-events: none;
    cursor: pointer;
  }
  .checkinCalboxx .dotcheckincircle.orange {
    background: orange;
    pointer-events: none;
    cursor: pointer;
  } 
  .checkinCalboxx .dotcheckincircle.yellow {
    background: yellow;
    pointer-events: none;
    cursor: pointer;
  }
  .checkinCalboxx .dotcheckincircle.yellowgreen {
    background: yellowgreen;
    pointer-events: none;
    cursor: pointer;
  }

  .summary-portal-label{
    height: 20px;
    width: 50px;
   }

.classplant{
   margin-top: 10px;
   margin-right: 5px;
   padding: 5px;
   float: center;
  
}

/* .fc .fc-daygrid-day-frame {
   
  border-bottom: none;
  margin: 0px;
 
} */

  .invoice-indicator {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 720;
    color: #6e6b7b;
    margin: 10px 0 0;
  }
  .invoice-indicator span {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    border-radius: 50%;
    background: #7367f0;
    display: inline-block;
  }
  
  .invoice-indicator .chkgreen span {
    background: #35bd59;
  }
  .invoice-indicator .chkorange span {
    background: orange;
  }
  .invoice-indicator .chkred span {
    background: red
  }
 
#root .loader_page{
  background: #000000ce;
}

/* .custom-approval-table.tablecured tr td:last-child {
  overflow: hidden;
} */
.tablcuredlastnew.tablecured tr td:last-child {
  overflow: hidden;
}