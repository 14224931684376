.form-control {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: -2px;
  width: 97%;
  height: 100%;
  background-position: right;
  background-size: 15px;
  cursor: pointer;
}

input.dateIconClick[type="date"]::-webkit-calendar-picker-indicator {
  bottom: 0;
  left: auto;
  position: absolute;
  right: 0;
  top: -2px;
  width: 10%;
  height: 100%;
  background-position: right;
  background-size: 15px;
  cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: -2px;
  width: 95%;
  height: 100%;
  background-position: right;
  background-size: 15px;
  cursor: pointer;
}

// .dashNews .ql-container {
//   margin-bottom: -64px !important;
// }
// .control-arrow {
//   background-color: black !important;
// }
.sort-overlap th {
  padding-right: 20px;
}

.check-cursor {
  cursor: context-menu;
}

.filterChange {
  border-color: red;
  color: red;
}

.newSpace th {
  padding: 0.75rem 31px;
}

.btn-successNew {
  color: green;
}

.btn-dangerNew {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editor-style h3,
.editor-style h4,
.editor-style h2,
.editor-style h1,
.editor-style p,
.editor-style strong,
.editor-style b {
  font-size: 15px;
  font-weight: normal;
  color: #5c4f4f;
}

.dark-theme .editor-style p{color: #000000;}

.assess-title {
  margin-bottom: 35px;
}

.assess-title h3,
.assess-title h4,
.assess-title h2,
.assess-title h1,
.assess-title p {
  font-size: 16px;
  color: #1964AF
}

.assess-title-list h3,
.assess-title-list h4,
.assess-title-list h2,
.assess-title-list h1,
.assess-title-list p {
  font-size: 16px;
}


// .survey-title h3,
// .survey-title h4,
// .survey-title h2,
// .survey-title h1,
// .survey-title p,
// .survey-title strong,
// .survey-title b {
//   margin-top: 25px;
//   margin-right: 34px;
//   font-size: 20px;
//   text-align: center;
// }

.survey-title-web h3,
.survey-title-web h4,
.survey-title-web h2,
.survey-title-web h1,
.survey-title-web p {
  font-size: 25px;
  text-align: center;
  display: flex;
  margin-bottom: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #000;
}

.ques-padding {
  padding-right: 5px !important;
}

.quesionBoxbig {
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
}

.questfirstheadtxt {
  margin-bottom: 1rem;
  font-weight: bold;
  display: none;
}

.nextrowasset-mar {
  margin-bottom: 20px;
}

.quesionBoxbig .questheadrowdisplay:nth-child(2) .questfirstheadtxt {
  display: block;
}

.quesionBoxbignewrow+.questheadrowdisplay .questfirstheadtxt {
  display: block;
}

.quesionBoxbignewrow+.questheadrowdisplay .questlistasshead {
  margin-top: 35px;
}

.feedbackform-satisfact {
  height: 40px !important;
  border-radius: 5px !important;
  // margin-bottom: 20px;
}

.feedbackform-satisfact-view {
  height: 250px !important;
  border-radius: 5px !important;
}

.quesionBoxbig .questheadrowdisplay:nth-child(2) .feedbackform-satisfact {
  margin-top: 38px;
}

.quesionBoxbig .questheadrowdisplay:nth-child(2) .feedbackform-satisfact-view {
  margin-top: 38px;
}

.quesionBoxbignewrow+.questheadrowdisplay .feedbackform-satisfact {
  margin-top: 38px;
}

.quesionBoxbignewrow+.questheadrowdisplay .feedbackform-satisfact-view {
  margin-top: 38px;
}


.questlistasshead h3,
.questlistasshead h4,
.questlistasshead h2,
.questlistasshead h1,
.questlistasshead p {
  font-size: 16px;
  font-weight: 400 !important;
  margin-bottom: 0;
  margin-top: 5px;
}


.smiley-icons {
  font-size: 30px;
  cursor: pointer;
}

.smilytxtsmall {
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
}

.dial-code-size {
  font-size: 15px;
}

@media print {
  @page {
    size: 250mm 260mm;
    margin: 5px 5px 5px 5px;
    padding: 0 0 0 0;
  }

  .container {
    width: 100%;
  }

  body {
    width: 1000px;
  }

  .btn-visible {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .questfirstheadtxt {
    margin-bottom: 5px;
    margin-top: 10px;
    display: block;
    font-size: 15px;
  }

  .questlistasshead h3,
  .questlistasshead h4,
  .questlistasshead h2,
  .questlistasshead h1,
  .questlistasshead p {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .quesionBoxbig .questheadrowdisplay .feedbackform-satisfact {
    margin-top: 36px;
  }

  .quesionBoxbig .questheadrowdisplay .feedbackform-satisfact-view {
    margin-top: 36px;
  }

  .assess-title {
    margin-bottom: 20px;
  }

  .smiley-icons {
    font-size: 20px;
    cursor: pointer;
  }

  .smilytxtsmall {
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
  }

  .quesionBoxbignewrow+.questheadrowdisplay .questlistasshead {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .quesionBoxbignewrow+.questheadrowdisplay .questlistasshead {
    margin-top: 0px;
  }

  .dial-code-size {
    font-size: 12px;
  }


  .mob-logoass {
    height: 80px;
    margin-bottom: 12px;
  }

  .survey-title-web h3,
  .survey-title-web h4,
  .survey-title-web h2,
  .survey-title-web h1,
  .survey-title-web p {
    font-size: 19px;
  }

  .quesionBoxbig {
    padding: 14px;
  }

  .assess-title h3,
  .assess-title h4,
  .assess-title h2,
  .assess-title h1,
  .assess-title p {
    font-size: 14px;
    margin-bottom: 11px;
  }

  .assess-title {
    margin-bottom: 25px;
  }

  .questfirstheadtxt {
    margin-bottom: 5px;
    margin-top: 10px;
    display: block;
    font-size: 15px;
  }

  .questlistasshead h3,
  .questlistasshead h4,
  .questlistasshead h2,
  .questlistasshead h1,
  .questlistasshead p {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .quesionBoxbig .questheadrowdisplay .feedbackform-satisfact {
    margin-top: 10px !important;
    margin-bottom: 0;
  }

  .quesionBoxbig .questheadrowdisplay .feedbackform-satisfact-view {
    margin-top: 10px !important;
    margin-bottom: 0;
  }

  .questheadrowdisplay {
    margin-bottom: 20px;
  }

  .nextrowasset-mar {
    margin-bottom: 0;
  }

  .smiley-icons {
    font-size: 17px;
    cursor: pointer;
  }

  .smilytxtsmall {
    font-size: 11px;
    line-height: 16px;
    margin-top: 5px;
  }

  .smiley-iconsbox .col-2 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

#rateRemarks::placeholder {
  line-height: 26px;
}

#sliderRemarks::placeholder {
  line-height: 36px;
}

.dark-theme .tablecured tr td {
  color: #e8e9ec !important
}
// .dark-theme .content-wrapper-inner{
//   background-color: #FFF !important;
// }
.viewformaftersubmit label{
  color: #000 !important;
}