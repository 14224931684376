/* The custom chekbox */

.logCheck {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	line-height: 22px;
	color: #000;
}


.modal-body .logCheck { padding-left: 30px; }


/* Hide the browser's default checkbox */

.logCheck input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
}


/* On mouse-over, add a grey background color */

.logCheck:hover input~.checkmark {
    background-color: #ffffff;
    border: 1px solid #ccc;
}


/* When the checkbox is checked, add a blue background */

.logCheck input:checked~.checkmark {
    background-color: #467FFF;
    border: 1px solid #467FFF;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.logCheck input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.logCheck .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}



.savetemplate .checkmark { width: 15px; height: 15px;}
.savetemplate .logCheck .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
}
.savetemplate .logCheck { padding-left: 21px; line-height: 18px; font-size: 13px;}