.main-pointer-remove .page-link:hover {
  background-color: #fff;
  color: #0195d4;
  cursor: default;
}
.main-pointer {
  cursor: pointer;
}
.modal {
  z-index: 999999999;
}
.Toastify {
  position: relative;
  z-index: 99999999999999;
}

.max-heightforrole {
  max-height: 200px;
}
.up-loposition {
  top: 24px;
}
.viewformaftersubmit label.logCheck {
  cursor: auto;
}

.timelinecontent-sec {
  margin-top: 10px;
}

.apphomeheadscroll .nav-tabs .nav-link {
  display: flex;
  white-space: nowrap;
  margin-bottom: 0;
}

.apphomeheadscroll .nav-tabs {
  flex-wrap: inherit;
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom: none;
}

.apphomeheadscroll .nav-tabs {
  scrollbar-color: rgba(24, 47, 81, 0.2) #e4e2e2;
  scrollbar-width: thin;
}
.apphomeheadscroll .nav-tabs::-webkit-scrollbar {
  width: 1px;
  height: 4px;
}
.apphomeheadscroll .nav-tabs::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #eee;
  border-radius: 5px;
}
.apphomeheadscroll .nav-tabs::-webkit-scrollbar-thumb {
  background-color: rgba(24, 47, 81, 0.2);
  border-radius: 5px;
}
.fc .fc-daygrid-day-frame {
  height: 30px;
}

.notificationbox p.last-login {
  color: #646464;
  font-size: 14px;
  margin: 10px 16px 0;
  border-bottom: #dfdfdf thin solid;
  padding-bottom: 5px;
}

.fc-eventbox h4 {
  text-overflow: ellipsis;
  overflow: hidden;
}
.caleventlistscroll {
  margin: 0 -10px 0 -15px;
  max-height: 446px;
  overflow-y: scroll;
  padding-right: 0 !important;
}

.dropdown-item button {
  font-size: 16px;
  color: #666666;
  padding: 0px;
}

.teambg {
  border: 1px solid #dbebf6;
}

.homenesnew-bg .ql-editor {
  padding: 0;
}
.homenesnew-bg .ql-container.ql-disabled .ql-tooltip {
  display: none;
}
.homenesnew-bg h4 p {
  -webkit-line-clamp: 3;
  color: #484848;
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
}
.homenesnew-bg p p {
  margin: 0;
}

.ql-editor {
  padding: 0;
}
.ql-tooltip {
  display: none;
}
.ql-container {
  font-size: inherit;
  font-family: "Calibri";
}

.fc .fc-toolbar.fc-header-toolbar {
  height: 58px;
}
.checkinCalbox .fc .fc-toolbar.fc-header-toolbar {
  height: auto;
}

.officeappbox {
  bottom: auto;
  z-index: 999999;
  top: 10%;
}

.carousel.carousel-slider .control-arrow {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background: #4a9bd4;
  line-height: 25px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  opacity: 1;
  z-index: 9999;
  position: absolute;
  top: 50%;
  border: #eee thin solid;
  padding: 0;
}
.carousel.carousel-slider .control-arrow:hover {
  background: #1964af;
}

.carousel .control-next.control-arrow:before {
  content: "\f054";
  color: #fff;
  border: none;
  font-size: 13px;
  margin: 0;
  display: block;
}

.carousel .control-prev.control-arrow:before {
  content: "\f053";
  color: #fff;
  border: none;
  font-size: 13px;
  margin: 0;
  display: block;
}

.tippy-popper {
  z-index: 999999 !important;
}

.extapptxt p {
  display: block;
  max-width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto 18px;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.blink {
  text-decoration: blink;
  animation-name: blinker;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .homenesnew-bg h4 p {
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
}

@media (min-width: 991px) {
  .apphomeheadscroll .tab-content {
    padding: 10px 23px 15px 23px;
    height: 153px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

// Autofill CSS

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.user-input {
  width: 250px;
  padding: 5px 3px;
}
.suggestions-list {
  list-style: none;
  padding: 0;
  max-height: 160px;
  overflow-y: auto;
  max-width: 250px;
}
.suggestion {
  background-color: rgb(226, 181, 181);
  padding: 10px;
  color: rgb(65, 65, 65);
  cursor: pointer;
}
.suggestion:hover {
  background-color: rgba(238, 238, 238, 0.1);
}
.selected {
  background-color: rgb(156, 177, 236);
  color: rgb(238, 223, 223);
}

.innergroup .modulesearchbox {
  position: relative;
  width: 100%;
  flex: 1 1 auto;
}
.modulesearchbox .form-control {
  font-size: 15px;
}
.modulesearchbox .suggestions-list {
  position: absolute;
  top: 40px;
  width: 100%;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  z-index: 1;
  border-radius: 5px;
}
.innergroup .modulesearchbox .suggestions-list {
  max-width: inherit;
}
.modulesearchbox .suggestion {
  background: none;
  font-size: 14px;
  padding: 5px 10px;
}
.modulesearchbox .selected {
  background: none;
  color: #1964af;
  font-weight: bold;
}
.modulesearchbox .suggestion:hover {
  color: #1964af;
}
.no-sugtxt {
  position: absolute;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 10px;
  font-size: 14px;
  height: auto !important;
  width: 100%;
}

.bs-tooltip-bottom {
  z-index: 9999999;
}

.carousel .slide iframe {
  width: 100%;
  margin: 0;
  height: calc(100vh - 160px);
}
.carousel .control-dots .dot {
  background: #1964af;
}
.carousel .slide {
  background: none !important;
}

.mobile-country-drop select.form-control {
  border-radius: 10px 0 0 10px;
}

.cc-image {
  padding: 0;
}

.react-confirm-alert-body {
  font-size: 14px;
}
.react-confirm-alert-body h1 {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}

.tablecured tr td:last-child .btn-blanktd {
  width: 21px;
}
.tablecured tr td:last-child .btn-blanktd + .btn-blanktd {
  margin-left: 0.5rem;
  width: auto;
}

.react-confirm-alert .react-confirm-alert-button-group button {
  background: #0195d4;
  border-color: #0195d4;
  color: #fff;
}

.mandatory-newlogout {
  background: #fff;
  border: 1px solid rgba(74, 155, 212, 0.2);
  padding: 5px 10px;
  color: #4a9bd4;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
}
.mandatory-newlogout img {
  width: 21px;
  height: 24px;
  margin: 0 !important;
  margin-right: 6px !important;
}
.mandatory-newlogout:hover {
  background: #1964af;
  color: #fff;
  font-weight: normal;
}
.mandatory-newlogout:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(200%) contrast(102%);
}

.popuptableselectwidth .basic-multi-select {
  min-width: 150px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
  border: #c2c7cd thin solid;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: #c2c7cd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: none;
  border: #4a9bd4 thin solid;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background: #4a9bd4;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.application-bg {
  height: 122px;
}
.homenewsdesign-bg {
  height: 193px;
}

.tippy-tooltip {
  font-size: 12px;
  padding: 2px 5px;
}
.quicklinktoop {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.orpromocodeline {
  text-align: center;
  margin: 12px 0 20px 0;
}
.orpromocodeline span:before {
  width: 46%;
  position: absolute;
  z-index: 1;
  content: "";
  top: 11px;
  border: rgba(0, 0, 0, 0.3) thin dashed;
  left: 0;
}
.orpromocodeline span:after {
  width: 46%;
  position: absolute;
  z-index: 1;
  content: "";
  top: 11px;
  border: rgba(0, 0, 0, 0.3) thin dashed;
  right: 0;
}
.orpromocodeline span {
  display: block;
  padding: 0px;
  position: relative;
  z-index: 2;
  font-size: 15px;
  font-weight: bold;
}
.loginTabs .azurelogin-btn {
  background: #185ca6;
  border-radius: 15px;
  font-size: 16px;
  padding: 5px;
  margin-top: 22px;
}

.meetingavtarimge {
  flex-direction: row-reverse;
}
.meetingavtarimge .team-profile {
  background-color: #fff !important;
  border: 2px solid #efefef !important;
  color: #4a9bd4;
  font-size: 11px;
  font-weight: bold;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -5px !important;
}

.meetingavtarimge .MuiAvatar-circular {
  background-color: #b7d4e7 !important;
  border: 2px solid #efefef !important;
  color: #4a9bd4 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  line-height: 26px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: -5px !important;
  height: 22px !important;
  width: 22px !important;
  margin-left: 0 !important;
}

.table-responsive.common-approvaltable {
  scrollbar-color: rgba(24, 47, 81, 0.4) #e4e2e2;
  scrollbar-width: thin;
}

.table-responsive.common-approvaltable::-webkit-scrollbar-thumb {
  background-color: rgba(24, 47, 81, 0.4);
  border-radius: 5px;
}

.viewpoOther.tablecured tr th,
.viewpoOther.tablecured tr td {
  font-size: 13px;
  padding: 7px 6px;
}
