:root {
    --basex: 10px;
    --basey: 10px;

    --white: #fff;
}

.heading {
    width: 100%;

    padding: calc(2 * var(--basex));

    display: flex;
    justify-content: space-between;
}

.heading p {
    margin-right: 00px;
}

.heading h3 {}

.agri-portal-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;

    padding: 0 calc(2 * var(--basex)) calc(2 * var(--basey)) calc(2 * var(--basex));
}

.left {}

.table_contain {
    padding: 0 calc(2 * var(--basex));
}

.ctas {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 calc(2 * var(--basex)) calc(2 * var(--basey)) calc(2 * var(--basex));
}

.ctas_left {}

.ctas_left div {
    margin-right: calc(2 * var(--basex));
}

.left {
    display: flex;
}

.left>div {
    margin-right: calc(2 * var(--basex));
}

.nodata {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}

.valuebox {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #0195D4;
    padding: 4px 12px;
    border-radius: 5px;

    color: #0195D4;
    height: 100%;
}

.agri-portal-container {
    width: 100%;
    height: 100%;

    padding: var 0;

}

.agri-portal-content {
    background-color: var(--white);

    padding: var(--basey) var(--basex);
    width: 100%;
}


.agri-portal-label {
    margin-right: 1rem;
    color: #0195D4;
}

.agri-portal-table table td,
.agri-portal-table table th {
    padding: 5px !important;
}

.agri-portal-controls input {
    height: 20px;
    font-size: 13px !important;
}

.agri-portal-controls select {
    padding: 0 0 0 5px !important;
    height: 20px !important;
    font-size: 13px !important;
    -webkit-appearance: checkbox !important;

}