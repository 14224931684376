.dark-theme body{background: #000;}
.dark-theme header{ background: #283046;}
.dark-theme .input-group{background: #161D30; border: 1px solid rgba(74, 155, 212, 0.3);}
.dark-theme .input-group-text:last-child {border: 1px solid #264361;}
.dark-theme .checkinbox {background: #161D30;}
.dark-theme .checkinbox h5 {color: #FFFFFF;}
.dark-theme .notifyicon {color: #FFFFFF;}
.dark-theme .mainpage-head {color: #FFFFFF !important;}
.dark-theme .homenewstxt p{color: #FFFFFF;}
.dark-theme .homenewstxt h6{color: #BDBDBD;}
.dark-theme .slick-dots li:hover button{background: #8F4BEE;}
.dark-theme .slick-dots li.slick-active button{background-color: #8F4BEE;}
.dark-theme .marquee{color: #FFFFFF;}
.dark-theme .approval-bg{border: 1px solid #C9E1F2; background: #283045;}
.dark-theme .text-danger{color: #E50027;}
.dark-theme .aprlTxt h5{color: #FFFFFF; background: #161D30; border: 1px solid #4186B9;}
.dark-theme .application-bg {background: linear-gradient(180deg, #283046 0%, #141A2B 100%);border: 1px solid #C4DCEE;}
.dark-theme .extapptxtinner{background: #161D30; box-shadow: inset -8px -8px 16px #4A9BD4, inset 8px 8px 16px #4A9BD4;}
.dark-theme .extapptxtinner span{background: #141A2B;border: 1px solid #F8E822;box-shadow: inset 8px 8px 16px #141A2B, inset -8px -8px 16px #161D30;}
.dark-theme .extapptxt p {color: #fff;}
.dark-theme .calendar-bg{background: linear-gradient(241.44deg, rgba(65, 176, 255, 0.26) 25.26%, rgba(74, 155, 212, 0.104) 82.53%);border: 1px solid #C4DCEE;}
.dark-theme .fc .fc-toolbar-title {color: #FFFFFF;}
.dark-theme .fc .fc-button .fc-icon{color: #FFFFFF;}
.dark-theme .calendorbox-border-right{border-right: 1px solid #C4DCEE;}
.dark-theme .fc .fc-toolbar.fc-header-toolbar {border-bottom: 1px solid #C4DCEE;}
.dark-theme .calhomeevent{border-bottom: 1px dashed #C4DCEE;}
.dark-theme .calhomtime {border-bottom: 1px solid #C4DCEE;}
.dark-theme .fc .fc-col-header-cell-cushion{color: #FFFFFF;}
.dark-theme .fc .fc-daygrid-day-number{color: #FFFFFF;}
// .dark-theme .holiday-bg h4{color: #FFFFFF;}
.dark-theme .fc-eventbox{background: #161D30;border-left: 2px solid #5A49FF;}
.dark-theme .fc-eventbox h4{color: #FFFFFF;}
.dark-theme .todobox-content{background: #161D30;}
.dark-theme .todobox-content h4{color: #FFFFFF;}
.dark-theme .todobox h3{color: #FFFFFF;}
.dark-theme .badge-primary{background: #4A9BD4; color: #FFFFFF;}
.dark-theme .calhomtime h4{color: #FFFFFF;}
.dark-theme .calhomtime p {color: #E7E7E7;}
.dark-theme .holiday-time h4{color: #FFFFFF;}
.dark-theme .holiday-time p{color: #FFFFFF;}
.dark-theme .todobox .btn-secondary{border: 1px solid rgba(74, 155, 212, 0.41);background: #161D30;}
.dark-theme .teambg {background: #283045;}
.dark-theme .teamtxt h5{color: #FFFFFF;}
.dark-theme .teamtxt p{color: #FFFFFF;}
.dark-theme .teamtime {color: #FFFFFF;}
.dark-theme .absent h6{color: #EB4335;}
.dark-theme .travel h6 {color: #0194D2;}
.dark-theme .bottomboxbg {background: #232A3D;}
.dark-theme .traitabs .nav-tabs .nav-link.active {color: #232A3D;}
.dark-theme .homecoussec{background: #232A3D; border: 1px solid #3E81B1;}
.dark-theme .homecoussec h4{color: #FFFFFF;}
.dark-theme .homecoussec h5{color: #C2C2C2;}
.dark-theme .homecoussec h6 i{background-color: #FFFFFF;}
.dark-theme .homecoussec h6{color: #C2C2C2;}
.dark-theme .anniversry-bg{background: #283045;border: 1px solid #C4DCEC;}
.dark-theme .birgday-listing h6{color: #BCBCBC;}
.dark-theme .birhead h5 {color: #BCBCBC;}
.dark-theme .btn-primary {background: #4A9BD4; border-color:#4A9BD4;}
.dark-theme .timelines h4{color: #FFFFFF;}
.dark-theme .timelines h5{color: #FFFFFF !important;}
.dark-theme .timelinecontent-sec{color: #FFFFFF;}
.dark-theme .timelines span {color: #C0C0C0;}
.dark-theme .fa-chevron-down:before { filter: invert(1);}
.dark-theme .checkinbox .fa-chevron-down:before { color: #000; }
.dark-theme .mandtop-user {background: #283046;}
.dark-theme .mandtop-user h2{color: #FFFFFF;}
.dark-theme .sidelogo {background: #283046;}
.dark-theme .approval-bg .nav-tabs .nav-link:hover{color: #0194D2;}
.dark-theme #scrollpart::-webkit-scrollbar-thumb {background-color: #4A9BD4;}
.dark-theme .leftmenubox {background: #283046;}
.dark-theme .left-menu .sub-menutxt li a{color: #FFFFFF;}
// .dark-theme .leftmenubox ul{overflow-y: hidden;}
.dark-theme .btn-create {background-color: #283046;}
.dark-theme .modal-content {background: #161D30;}
.dark-theme .modal-content .teamtxt p{color: #FFFFFF !important;}
.dark-theme .innergroup label {color: #FFFFFF;}
.dark-theme .innergroup textarea.form-control{background: #283045; border: 1px solid #D8D6DE; color: #B9B9C3;}
.dark-theme .addminushtml input{background: #161D30; color: #FFFFFF;}
.dark-theme .addminushtml button{color: #FFFFFF;} 
.dark-theme .darkbtn img { filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(164deg) brightness(102%) contrast(102%);}
.dark-theme .content-wrapper-inner{background: #283045;}
.dark-theme .breadcrumb li a {color: #FFFFFF;}
.dark-theme .css-1s2u09g-control{background-color: #283045;}
.dark-theme .dropdown-menu{background-color: #161D30;}
.dark-theme .dropdown-item {color: #FFFFFF;}
.dark-theme .dropdown-item:focus, .dark-theme .dropdown-item:hover {background-color: #0194D2;}
.dark-theme .innergroup .form-control {background-color: #283045; color: #B9B9C3;}
.dark-theme .innergroup select.form-control option { color: #dddddd; }
.dark-theme .css-14el2xx-placeholder{color: #B9B9C3;}
.dark-theme .tablecured tr td {color: #FFFFFF; }
.dark-theme .tablecured thead th{color: #fff;}
.dark-theme .profilesecdetail h4{color: #FFFFFF;}
.dark-theme .profilesecdetail h5{color: #BDBDBD;}
.dark-theme .notificationbox {background-color: #141A2B;}
.dark-theme .usermyprofile li a{color: #FFFFFF;}
.dark-theme .usermyprofile li {color: #FFFFFF;}
.dark-theme .profile-detail h3 {color: #FFFFFF;}
.dark-theme .profiletab .tab-content {border-top: 1px solid #FFFFFF;}
.dark-theme .sortinglist select{background-color: #283045;}
.dark-theme .sortinglist{color: #FFFFFF;}
.dark-theme .package-section {background-color: #283046;}
.dark-theme .package-section h6 {color: #FFFFFF;}
.dark-theme .pack-details p{color: #FFFFFF;}
.dark-theme .pack-details h5 {color: #C2C2C2;}
.dark-theme .analytic-content {background: #283046;}
.dark-theme .analytic-content h3{color: #FFFFFF;}
.dark-theme .analytic-content h4 {color: #BCBCBC;}
.dark-theme .chart-boxes{background: #232A3D;}
.dark-theme .apexcharts-legend-text {color: #B9B9C3 !important;}
.dark-theme .poll-sna-head select{background: #161D30; color: #FFFFFF;}
.dark-theme .poll-sna-box h4{color: #FFFFFF;}
.dark-theme .poll-sna-box h4 span{color: #C4DCEE;}
.dark-theme .anarolesdetails h3{color: #FFFFFF;}
.dark-theme .anarolesdetails h4{color: #FFFFFF;}
.dark-theme .anarolesdetails p{color: #FFFFFF;}
.dark-theme .scrollroles ul li{color: #FFFFFF;}
.dark-theme .ticketbg h4 {color: #FFFFFF;}
.dark-theme .accordion-body p{color: #A4A4A4;}
.dark-theme .policylistnames{background: #283045;}
.dark-theme .policylistnames a{color: #FFFFFF;}
.dark-theme .notifytabs .nav-tabs .nav-link.active, .dark-theme .notifytabs .nav-tabs .nav-link.active:hover {color: #FFFFFF; border-bottom: 2px solid #FFFFFF !important;}
.dark-theme .no-read {background: #283046;}
.dark-theme .notftsection h4{color: #FFFFFF;}
.dark-theme .notificationbox .input-group-text h6{color: #FFFFFF;}
.dark-theme .notftsection h4 span {color: #A4A4A4;}
.dark-theme .policylistnames li {color: #FFFFFF;}
.dark-theme .policy-boxhead {background: #141A2B; border: 1px solid #FFFFFF;}
.dark-theme .policy-boxhead h4 {color: #FFFFFF;}
.dark-theme .ideabox {background-color: #141A2B;}
.dark-theme .ideabox h4{color: #BDBDBD;}
.dark-theme .ideabox h5{color: #FFFFFF;}
.dark-theme .ideabox h6{color: #C0C0C0;}
.dark-theme .ideabox .bg-light{background-color: #232A3D !important;}
.dark-theme .ideabox .btn-blanktd{color: #FFFFFF !important}
.dark-theme .ideabox .btn-blanktd:hover{color: #0194D2 !important}
.dark-theme .ideaboxleft{background: #283045;}
.dark-theme .totalidea p{color: #FFFFFF;}
.dark-theme .innerheadsec .bg-light {background: #141A2B !important;}
.dark-theme .innergroup .bg-white {background: #283045 !important;}
.dark-theme .notifytabs .nav-tabs .nav-link:hover{color: #0194D2;}
.dark-theme .apexcharts-menu{background: #232A3D; color: #FFFFFF;}
.dark-theme .apexcharts-theme-light .apexcharts-menu-item:hover {background: #0194D2 !important;}
.dark-theme .btn-secondary-inner{background-color: #283045; color: #FFFFFF;}
.dark-theme .btn-secondary-inner img { filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(164deg) brightness(102%) contrast(102%) }
.dark-theme .table-bordered td {border: 0px; border-bottom: 2px solid #2e2e2e;}
.dark-theme .table-bordered th{border: none; border-bottom: 2px solid #2e2e2e; color: #FFFFFF;}
.dark-theme .tablecured {border: none !important;}
.dark-theme .accordion-button{background-color: #283045; color: #FFFFFF;}
.dark-theme .admin-guide-data h3{color: #FFFFFF;}
.dark-theme .admin-guide-data p{color: #BDBDBD;}
.dark-theme .admin-guide-data .text-black{color: #FFFFFF !important;}
.dark-theme .admin-guide-data ul li{color: #FFFFFF;}
.dark-theme .module-head{background: #141A2B;}
.dark-theme .adminguidelist .accordion-button.collapsed{color: #BDBDBD;}
.dark-theme .adminguidelist .accordion-button .fa-chevron-down{filter: invert(1);}
.dark-theme .help-deskbg h2{color: #FFFFFF;}
.dark-theme .help-deskbg h3{color: #C0C0C0;}
.dark-theme .help-deskbg h4{color: #FFFFFF;}
.dark-theme .adminboxes .userboxes {background: #141A2B;}
.dark-theme .roletoreluser h6{color: #BCBCBC;}
.dark-theme .userboxes h5 {color: #FFFFFF;}
.dark-theme .userboxes{background-color: #283045;}
.dark-theme .officeappbox{background-color: #141A2B;}
.dark-theme .modaldefaultclose .btn-close {filter: invert(1);}
.dark-theme .tablecured td .form-control{background-color: #283045; color: #FFFFFF;}
.dark-theme .input-group .form-control {color: #B9B9C3;}
.dark-theme .select__control {background-color: #283045;}
.dark-theme .select_control select option{background-color: #0194D2;}
.dark-theme .css-yt9ioa-option { background-color: #283045 !important; color: #B9B9C3;}
.dark-theme .css-yt9ioa-option option:hover {background-color: red !important;}

.dark-theme .modal-footer {border-top:1px solid #141823}
.dark-theme .modaldefaultclosecenter .modal-header{background: rgba(255, 255, 255, 0.15);}
.dark-theme .drapdroparea, .dark-theme .notclickdrop{ background-color: #161D30;}
.dark-theme .drapdroparea p, .dark-theme .notclickdrop p {color: #BCBCBC;}
.dark-theme .uploadrecordstab tr th{color: #FFFFFF;}
.dark-theme .text-theme {color: #0195D4 !important;}
.dark-theme .uploadrecordstab tr td{color: #FFFFFF;}
.dark-theme .leave-apply-section{background-color: #141823;}
.dark-theme .leave-apply-section h5.text-dark{color: #FFFFFF !important;}
.dark-theme .leavestatustab td:nth-child(2){color: #FFFFFF;}
.dark-theme .dot-blue{color: #FFFFFF;}
.dark-theme .leave-apply-section h6{color: #B9B9C3;}
.dark-theme .tablecured td .text-dark{color: #B9B9C3 !important;}
.dark-theme .page-headapprover{background-color: #283045; color: #BDBDBD;}
.dark-theme .purchaseinnertabs .tab-content .bg-light{background-color: #161D30 !important;}
.dark-theme .f-14{color: #FFFFFF;}
.dark-theme .f-14 .text-muted{color: #C2C2C2 !important;}
.dark-theme .travel-note h4{color: #FFFFFF;}
.dark-theme .travel-note ul li{color: #FFFFFF;}
.dark-theme .news-detail h2{color: #FFFFFF;}
.dark-theme .news-detail h4 {color: #FFFFFF;}
.dark-theme .perhead h4 {color: #FFFFFF;}
.dark-theme .permission-box h4 {color: #FFFFFF;}
.dark-theme .permission-box h5 {color: #FFFFFF;}
.dark-theme .permission-box h6{color: #BDBDBD;}
.dark-theme .permission-box .logCheck{color: #FFFFFF;}
.dark-theme .addusertabs > .nav-tabs{background-color: #283045;}
.dark-theme .addusertabs > .nav-tabs .nav-link{background-color: #141A2B; color: #FFFFFF;}
.dark-theme .addusertabs > .nav-tabs .nav-link span {background-color: #141823;}
.dark-theme .addusertabs > .nav-tabs .nav-link.active, .dark-theme .addusertabs > .nav-tabs .nav-link:hover {color: #0195D4 !important;}
.dark-theme .addusertabs > .tab-content{background-color: #283045;}
.dark-theme .leavetableapply .form-control:disabled {background-color: #283045 !important;}

.dark-theme .question-box{background-color: #141823;}
.dark-theme .question-box h4{color: #FFFFFF;}
.dark-theme .css-6j8wv5-Input {color: #B9B9C3 !important;}
.dark-theme .ck-reset_all :not(.ck-reset_all-excluded *), .dark-theme .ck.ck-reset, .dark-theme .ck.ck-reset_all {background-color: #D9EFF8; color: #212529;} 
.dark-theme .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {background-color: #283045 !important;}
.dark-theme .ck.ck-editor__editable_inline p {color: #BDBDBD;}
.dark-theme .ck.ck-editor__main>.ck-editor__editable{background-color: #283045;}
.dark-theme .innergroup p{color: #FFFFFF !important;}

.dark-theme .surveyviewquestions h3{color: #FFFFFF;}
.dark-theme .surveyviewquestions p{color: #000;}
.dark-theme .surveyviewquestions .m-2 p{color: #FFFFFF;}
.dark-theme .surveyviewquestions .m-2 h4{color: #FFFFFF;}
.dark-theme .question-boxpollsurvey h5{color: #FFFFFF;}
.dark-theme .answerboxpollsuy span{color: #FFFFFF;}
.dark-theme .ratingpoll .checkmark{color: #FFFFFF;}
.dark-theme .ratingpoll span{color: #FFFFFF;}
.dark-theme .btn-blanktd:hover{color: #FFFFFF !important;}
.dark-theme .tab-content .fw-bold{color: #FFFFFF;}
.dark-theme .tab-content .text-muted{color: #FFFFFF !important;}
.dark-theme .innergroup span{color: #A4A4A4;}
.dark-theme .ticketviewbox{background-color: #283045;}
.dark-theme .ticketviewbox h3{color: #FFFFFF;}
.dark-theme .ticketviewbox li {color: #FFFFFF;}
.dark-theme .ticketviewbox h4 {color: #BCBCBC;}
.dark-theme .ticketviewbox h5{color: #FFFFFF;}
.dark-theme .ticketviewbox h6{color: #FFFFFF;}
.dark-theme .ticketviewbox p {color: #BCBCBC;}
.dark-theme .viewraisehead{color: #FFFFFF;}
.dark-theme .agent h4{ color: #C0C0C0;}
.dark-theme .ticketreplytxt{background: #141A2B; color: #FFFFFF;}
.dark-theme .agent h6{color: #FFFFFF;}
.dark-theme .raisereplybox textarea{background: #283046; color: #FFFFFF !important;}
.dark-theme .raisereplybox textarea::placeholder{color: #FFFFFF;}
.dark-theme .timeline-box h4 {color: #FFFFFF;}
.dark-theme .timeline-box h5{color: #BDBDBD;}
.dark-theme .timeline-box h6{color: #FFFFFF;}
.dark-theme .timeline-box p {color: #BCBCBC;}
.dark-theme .backleftlist span {background-color: #141823; color: #FFFFFF;}

.dark-theme .assess-title h3,
.dark-theme .assess-title h4,
.dark-theme .assess-title h2,
.dark-theme .assess-title h1,
.dark-theme .assess-title p {
  font-size: 16px;
  color: #fff
}


.dark-theme .aprlTxt p, .dark-theme .officebox span, .dark-theme .applicationTxt p { color: #fff;}
.dark-theme aside li { background: #222e4e;}
.dark-theme .notificationbox p.last-login { color: #bdbdbd; }
.dark-theme .dropdown-item button {color: #FFFFFF;}
.dark-theme .left-menu li { background: none;}
.dark-theme aside li:hover, .dark-theme aside li.active { background: #1964af; }
.dark-theme .homenesnew-bg .tab-content, .dark-theme .homenewsdesign-bg { border: 1px solid #C4DCEC; background: linear-gradient(180deg, rgba(74, 155, 212, 0.2) 0%, rgba(74, 155, 212, 0.2) 100%);}
.dark-theme .teambg {border: 1px solid #C4DCEE; }
.dark-theme .noticeheading h5 { color: #dfdfdf; }
.dark-theme .noticetxtnew { color:#e9e9e9 }
.dark-theme .applicationTxt { background: url(../images/appl-bg-dark.png) no-repeat; background-size: contain; background-position: center;}
.dark-theme .apphomeheadscroll .nav-tabs::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: #161D30;
    border-radius: 5px;
  } 
.dark-theme .homenesnew-bg .nav-tabs { background: #272f44; }
.dark-theme .homenesnew-bg .nav-tabs .nav-link { color: #e9e9e9;}
.dark-theme .text-muted { color: #e9e9e9 !important;}

.dark-theme .basic-multi-select input { color: #B9B9C3 !important; }
.dark-theme .select__menu-list { background: #283045; color: #B9B9C3;}
.dark-theme .select__option:hover, .dark-theme .select__option--is-focused { color: #000 !important;}
.dark-theme .select__single-value { color: #B9B9C3 !important; font-size: 15px;} 

.dark-theme .myteam-table th { background: #283045; }
.dark-theme .myteam-table td { background: #161d30; }
.dark-theme .myteam-table td .teamtxt p { color: #e9e9e9; }
.dark-theme .popupcheckintime h2 { color: #fff; }

.dark-theme .emp-digicontent h2, .dark-theme .emp-digicontent h3, .dark-theme .emp-digicontent h5 { color: #a7a7a7; }
.dark-theme .emp-digitalbox .btn-close { filter: invert(0);}
.dark-theme .emp-digicontent .icondesign img { filter: invert(1); }
.dark-theme .tickethistorbg { background: #151b2d; }

.dark-theme .modulesearchbox .suggestions-list { background: #161c2e; }
.dark-theme .suggestion { color: #ffffff; }
.dark-theme .modulesearchbox .suggestion:hover, .dark-theme .modulesearchbox .selected { color: #4a9bd4; }
.dark-theme .no-sugtxt { background: #161c2e; color: #ffffff;}
.dark-theme .notifytabs .badge { background: #283046 !important; color: #ffffff; }
