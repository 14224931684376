.asshcathead { background: #0068b4; color: #fff !important; padding: 10px; margin-top: 15px; margin-bottom: 0; }
.policyheadasssubhead { border: none; text-align: left; background: #c1e7ff; padding: 10px; }


.brandingcrousel .carousel .control-dots {
    display: inline-block !important;
    width: auto !important;
}

.otv-message-sap { text-overflow: inherit !important; overflow: inherit !important; white-space: normal !important }


.viewpoprinttab.tablecured tr td, .viewpoprinttab.tablecured tr th {
    white-space: normal;
font-size: 11px;
    padding: 2px;

}