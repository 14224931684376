
:root{
    --basex:10px;
    --basey:10px;

    --white:#fff;
}

.heading{
    width: 100%;

    padding: calc(2 * var(--basex));
}
.heading h3{

}

.controls{
    width: 100%;
    display: flex;
    justify-content: space-between;

    padding:0 calc(2 * var(--basex)) calc(2 * var(--basey)) calc(2 * var(--basex));
}
.left{

}
.table_contain{
    padding:0 calc(2 * var(--basex));
}

.nodata{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
} 
.valuebox{
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #0195D4;
    padding: 4px 12px;
    border-radius: 5px;

    color: #0195D4;
    height: 100%;
}

.agri-portal-container{
    width: 100%;
    height: 100%;

    padding: 10px 0px;
    background-color: #fff;


}
.agri-portal-content{
    padding: 10px 10px;
    background-color: #fff;
}

.agri-portal-controls{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:0 calc(2 * var(--basex)) calc(2 * var(--basey)) calc(2 * var(--basex));
}


.agri-portal-table table td,
.agri-portal-table table th{
    padding: 5px !important;
}

.agri-portal-label{
    margin-right: 1rem;
    color: #0195D4;
}

.agri-portal-controls select{
    padding: 0 0 0 5px !important;
    height: 20px !important;
    font-size: 15px !important;
    margin-bottom: 20px;
    -webkit-appearance: checkbox !important;
    
}

.bold{
    font-weight: bold;
}
